import {
  arbitrum,
  base,
  linea,
  mainnet,
  optimism,
  polygon,
  scroll,
} from '@reown/appkit/networks'
import { createAppKit } from '@reown/appkit/vue'
import { ref } from 'vue'

import { wagmiAdapter } from '@/composables/config'

const projectId = import.meta.env.VITE_WALLET_CONNECT_PROJECT_ID
const metadata = {
  name: 'Asset Overview',
  description: 'Asset Overview',
  url: window.location.origin,
  icons: [`${window.location.origin}/arcana-favicon.png`],
}

const web3Modal = ref<any>(null)

export const useWalletConnect = () => {
  const initializeWeb3Modal = () => {
    web3Modal.value = createAppKit({
      adapters: [wagmiAdapter],
      networks: [mainnet, arbitrum, polygon, base, optimism, linea, scroll],
      projectId,
      metadata,
      features: {
        email: false,
        socials: false,
        emailShowWallets: false,
      },
      themeMode: 'light',
      themeVariables: {
        '--w3m-font-family': '"Inter", sans-serif',
        '--w3m-accent': '#FF4E9F',
      },
    })
  }

  if (!web3Modal.value) {
    initializeWeb3Modal()
  }

  return {
    web3Modal,
  }
}
