import {
  arbitrum,
  base,
  linea,
  mainnet,
  optimism,
  polygon,
  scroll,
} from '@reown/appkit/networks'
import { WagmiAdapter } from '@reown/appkit-adapter-wagmi'

const projectId = import.meta.env.VITE_WALLET_CONNECT_PROJECT_ID

export const wagmiAdapter = new WagmiAdapter({
  networks: [mainnet, arbitrum, polygon, base, optimism, linea, scroll],
  projectId,
})
