<script setup lang="ts">
import { Dialog, Progress } from '@ark-ui/vue'
import { useAppKitAccount } from '@reown/appkit/vue'
import Decimal from 'decimal.js'
import { maxUint256 } from 'viem'
import { computed, ref, watch } from 'vue'

import CloseIcon from '@/assets/images/svg/Close.svg'
import SetupFail from '@/components/layout/OnboardingSetup/SetupFail.vue'
import SetupOverview from '@/components/layout/OnboardingSetup/SetupOverview.vue'
import SetupStep1 from '@/components/layout/OnboardingSetup/SetupStep1.vue'
import SetupStep2 from '@/components/layout/OnboardingSetup/SetupStep2.vue'
import SetupSuccess from '@/components/layout/OnboardingSetup/SetupSuccess.vue'
import { getGasFromFaucet } from '@/services/gasSponsor'
import { useAllowanceStore } from '@/stores/allowance'
import { useUserStore } from '@/stores/user'
import { Asset, Breakdown } from '@/types/balanceTypes'
import { Chain } from '@/types/chainTypes'
import { useCaSdkAuth } from '@/use/CaSdkAuth'
import { MAINNET_CHAINS } from '@/utils/constants'
import { devLogger } from '@/utils/devLogger'
import { ethers } from 'ethers'
import { getUniqueChainsAndBalances } from '@/utils/commonFunction'

type SetupStage =
  | 'overview'
  | 'step1'
  | 'step2'
  | 'success'
  | 'fail'
  | 'loading'

const user = useUserStore()
const useAccount = useAppKitAccount()
const allowanceStore = useAllowanceStore()
const setupStage = ref<SetupStage>('overview')
const selectedOption = ref<'L2Only' | 'L1+L2'>('L1+L2')
const localModalOpen = ref(allowanceStore.showAllowanceSetupModal)
const currentChain = ref(0)
const totalAllowances = ref(14)
const currentAllowance = ref(0)

const proceedFromStep1 = (ev: 'L2Only' | 'L1+L2') => {
  selectedOption.value = ev
  if (ev === 'L2Only') {
    setupStage.value = 'loading'
  } else {
    setupStage.value = 'step2'
  }
}

const loaderValue = computed(() => {
  return (currentAllowance.value / totalAllowances.value) * 100
})

const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

const waitForBalances = async () => {
  const chainsToCheck = [10, 137, 8453, 42161]
  if (selectedOption.value === 'L1+L2') {
    chainsToCheck.push(1)
  }
  const caSdkAuth = await useCaSdkAuth()
  if (caSdkAuth) {
    const assets = await caSdkAuth.getUnifiedBalances()
    console.log(assets)
    const balances = await getUniqueChainsAndBalances(assets)

    const hasBalances = await balances.some(
      (chainBalance) =>
        chainsToCheck.includes(chainBalance.chain.id) &&
        ethers.parseEther(String(chainBalance.totalBalance)) > 0n,
    )
    console.log('hasBalances:', hasBalances)
    if (!hasBalances) {
      await sleep(300)
      return waitForBalances()
    }

    return true
  }
}

const getChainById = (chainId: number) => {
  return MAINNET_CHAINS.find((chain) => chain.id === chainId) || ({} as Chain)
}

const handleSetupAllowance = async () => {
  const caSdkAuth = await useCaSdkAuth()
  if (!caSdkAuth) return

  const allowances =
    selectedOption.value === 'L1+L2'
      ? allowanceStore.userAllowances
      : allowanceStore.userAllowances &&
        allowanceStore.userAllowances.filter(
          (allowance) => allowance.chainID !== 1,
        )
  const L2ChainIds = [10, 137, 42161, 8453]

  const balances = await getUniqueChainsAndBalances(user.assets)
  console.log(balances, 'checkingBalances')
  const hasBalances = await balances.some(
    (chainBalance) =>
      L2ChainIds.includes(chainBalance.chain.id) &&
      ethers.parseEther(String(chainBalance.totalBalance)) > 0n,
  )

  console.log(hasBalances, 'bbbbb')

  totalAllowances.value = (allowances && allowances.length + 1) || 0
  devLogger.log('Allowances', allowances)
  if (!hasBalances) {
    devLogger.log('Getting gas from faucet')
    try {
      const faucetResult = await getGasFromFaucet(
        useAccount.value.address as string,
      )
      console.log(faucetResult)

      const { successChains, failedChains } = faucetResult
      console.log(failedChains, successChains, 'failedChains')
      if (failedChains && failedChains.length > 0) {
        let sendChain = failedChains.length > 3 ? failedChains : successChains
        console.log(sendChain)

        const retryResult = await getGasFromFaucet(
          useAccount.value.address as string,
          sendChain,
        )
        console.log('Retry Result:', retryResult)
      }
    } catch (e) {
      devLogger.error('Failed to get gas from faucet', e)
      setupStage.value = 'fail'
      return
    }
    await waitForBalances()
  }
  currentAllowance.value += 1
  devLogger.log('Gas received from faucet')
  devLogger.log('Setting up allowances')
  if (allowances) {
    for (const allowance of allowances) {
      const chain = getChainById(allowance.chainID)
      currentChain.value = chain.id

      try {
        devLogger.log('Approving allowance', allowance)

        await caSdkAuth
          .allowance()
          .tokens([allowance.token])
          .chain(allowance.chainID)
          .amount(maxUint256)
          .set()

        currentAllowance.value += 1
        devLogger.log('Allowance approved', allowance)
      } catch (e) {
        devLogger.error('Allowance failed', e)
      }
    }
    await allowanceStore.userAllowances
    if (currentAllowance.value === totalAllowances.value) {
      setupStage.value = 'success'
      devLogger.log('Allowances setup complete')
    } else {
      setupStage.value = 'fail'
    }
  }
}

const handleModalDismiss = () => {
  allowanceStore.setAllowanceSetupModal(false)
  setupStage.value = 'overview'
}

watch(setupStage, async (stage) => {
  if (stage === 'loading') {
    await handleSetupAllowance()
  }
})

watch(
  () => allowanceStore.showAllowanceSetupModal,
  (newVal) => {
    localModalOpen.value = newVal
  },
)
</script>

<template>
  <Dialog.Root v-model:open="localModalOpen" :close-on-interact-outside="false">
    <Teleport to="body">
      <Dialog.Backdrop />
      <Dialog.Positioner
        class="fixed inset-0 flex items-center justify-center z-50"
        :class="{ 'bg-orange-400': localModalOpen }"
      >
        <Dialog.Content class="card">
          <div
            class="w-full flex justify-end cursor-pointer"
            @click.stop="handleModalDismiss"
          >
            <CloseIcon class="h-5 w-5" />
          </div>
          <div class="mt-5">
            <SetupOverview
              v-if="setupStage === 'overview'"
              @next="setupStage = 'step1'"
            />
            <SetupStep1
              v-else-if="setupStage === 'step1'"
              @back="setupStage = 'overview'"
              @proceed="proceedFromStep1"
            />
            <SetupStep2
              v-else-if="setupStage === 'step2'"
              @cancel="setupStage = 'step1'"
              @confirm="setupStage = 'loading'"
            />
            <SetupSuccess
              v-else-if="setupStage === 'success'"
              :selected-option="selectedOption"
              @done="handleModalDismiss"
            />
            <SetupFail
              v-else-if="setupStage === 'fail'"
              @retry="setupStage = 'loading'"
            />
            <div
              v-else-if="setupStage === 'loading'"
              class="flex flex-col items-center gap-4"
            >
              <video
                src="@/assets/videos/new_loader.webm"
                autoplay
                loop
                muted
                class="h-32"
                @contextmenu.prevent="void 0"
              />
              <span
                class="text-2xl font-nohemi font-semibold text-blueGray-800 tabular-nums max-sm:text-xl"
                >Getting it Done...</span
              >
              <span
                class="text-base font-inter text-center font-medium text-blueGray-600 tabular-nums max-sm:text-xs"
                >Setup will be completed in a few moments. But feel free to
                close this window and the task will be completed in the
                background.</span
              >
              <Progress.Root class="w-full text-center" :value="loaderValue">
                <Progress.Track
                  class="bg-tertiary-700 rounded-full w-full h-4 p-0.5"
                >
                  <Progress.Range
                    class="bg-primary-100 rounded-full h-full transition-all duration-200 will-change-[width]"
                  />
                </Progress.Track>
                <Progress.Label
                  v-if="currentChain !== 0"
                  class="text-xs font-inter font-normal text-blueGray-600 text-center mx-auto max-sm:text-0.625rem"
                  >Setting up allowances on
                  {{ getChainById(currentChain).name }}</Progress.Label
                >
                <Progress.Label
                  v-else
                  class="text-xs font-inter font-normal text-blueGray-600 max-sm:text-0.625rem"
                  >Getting gas from the faucet</Progress.Label
                >
              </Progress.Root>
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Positioner>
    </Teleport>
  </Dialog.Root>
</template>
