<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import FadeLoader from 'vue-spinner/src/FadeLoader.vue'

import AllowanceAsset from '@/components/layout/AllowanceSetup/AllowanceAsset.vue'
import { useAllowanceStore } from '@/stores/allowance'
import { AllowanceDetailsTypes } from '@/types/allowanceDetailsTypes'
import { useCaSdkAuth } from '@/use/CaSdkAuth'
import AppBalance from './AppBalance.vue'

const router = useRouter()
const allowanceStore = useAllowanceStore()
const allowanceDetails = ref<AllowanceDetailsTypes[]>([])
const assetLoader = ref<boolean>(true)

const loadAssets = async () => {
  const caSdkAuth = await useCaSdkAuth()
  if (caSdkAuth) {
    const allowance = await caSdkAuth.allowance().get()
    console.log(allowance, 'allow')
    const sortedData = allowance.sort((a, b) => a.chainID - b.chainID)
    allowanceDetails.value = sortedData
    assetLoader.value = false
  }
}

const areAllBalancesZero = computed((): boolean => {
  if (allowanceStore.userAllowances) {
    return allowanceStore.userAllowances.every(
      (item: AllowanceDetailsTypes) => item.allowance === 0n,
    )
  }
  return false
})

onMounted(() => {
  loadAssets()
})
</script>

<template>
  <div class="flex-1 p-12 overflow-auto max-sm:p-6">
    <div class="">
      <div class="hidden justify-between items-center sm:flex">
        <h2 class="font-nohemi text-2xl font-normal leading-10 text-gray-800">
          Allowances
        </h2>
      </div>

      <div
        class="max-sm:relative max-sm:-m-6 max-sm:bg-background-500 max-sm:mb-4 sm:hidden"
      >
        <AppBalance title="Allowances" />
      </div>

      <div
        class="flex items-center justify-end gap-4 rounded-full max-md:hidden"
      >
        <button
          class="button-primary w-56 h-11"
          @click.stop="router.push({ name: 'AllowanceSetup' })"
        >
          New Token Allowance
        </button>
        <button
          v-if="areAllBalancesZero === true"
          class="button-primary w-56 h-11"
          @click.stop="allowanceStore.setAllowanceSetupModal(true)"
        >
          Setup Chain Abstraction
        </button>
      </div>

      <div class="mt-4">
        <div class="flex items-center gap-4 my-2">
          <img src="@/assets/images/svg/Arcana.svg?url" alt="Logo" />
          <span class="text-base font-inter font-semibold text-blueGray-600"
            >ARCANA VAULT CONTRACT V1</span
          >
        </div>
        <div class="w-full flex justify-center">
          <FadeLoader :loading="assetLoader" color="#1D2A31" size="16rem" />
        </div>
        <AllowanceAsset
          v-if="!assetLoader"
          :allowance-details="allowanceDetails"
        />
      </div>
    </div>
  </div>
</template>
