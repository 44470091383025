<script setup lang="ts">
import InfoIcon from '@/assets/images/svg/InfoCircle.svg'

const emit = defineEmits(['next'])
</script>

<template>
  <div class="flex flex-col gap-4">
    <h3
      class="font-nohemi font-semibold text-2xl text-center px-4 text-blueGray-800 max-sm:text-xl"
    >
      Setup the Smoothest Wallet Experience
    </h3>
    <div
      class="field rounded-xl flex flex-col gap-3 p-4 bg-background-600 border border-background-400"
    >
      <span
        class="text-blueGray-600 text-base font-inter font-normal text-start max-sm:text-xs"
        >For this, the following steps are required:</span
      >
      <ol class="flex flex-col gap-2">
        <li
          class="bg-background-400 text-blueGray-600 text-base font-inter font-normal rounded-lg flex items-start gap-2 p-4"
        >
          <span
            class="text-blueGray-700 font-bold text-base tabular-nums max-sm:text-xs"
            >1.</span
          >
          Choose which chains you’d like to set this up on, Layer 2’s only which
          is free or Layer 1 also which requires a small payment
        </li>
        <li
          class="bg-background-400 text-blueGray-600 text-base font-inter font-normal rounded-lg flex items-start gap-2 p-4"
        >
          <span
            class="text-blueGray-700 font-bold text-base tabular-nums max-sm:text-xs"
            >2.</span
          >
          <div>
            Creation of ‘Allowances’ to enable automatic fund collection for all
            supported tokens across the chains chosen in step 1.
            <a
              href="#"
              target="_blank"
              class="font-bold text-base font-inter no-underline text-rose-500 outline-none border-none active:border-none hover:border-none active:outline-none hover:outline-none active:no-underline hover:no-underline max-sm:text-xs"
              >Learn More</a
            >
          </div>
        </li>
      </ol>
    </div>
    <div
      class="bg-blue-600 text-white-100 flex items-start gap-3 rounded-lg p-4 font-inter"
    >
      <InfoIcon
        class="h-5 w-5 flex-shrink-0 stroke-white-100 stroke-cap-round"
      />
      <span class="text-base font-normal max-sm:text-0.625rem"
        >You will ALWAYS be in control. Modify or delete any allowance being
        created here by going to Settings > Allowances.</span
      >
    </div>
    <button class="button-secondary w-full mt-4" @click.stop="emit('next')">
      Next
    </button>
  </div>
</template>
