<script setup lang="ts">
import VueQrcode from '@chenfengyuan/vue-qrcode'
import { useAppKitAccount } from '@reown/appkit/vue'
import Decimal from 'decimal.js'
import { zeroAddress } from 'viem'
import { computed, ref, watch } from 'vue'

import AppTooltip from '@/components/shared/AppTooltip.vue'
import { getCoinbasePrices } from '@/services/coinbase'
import { useUserStore } from '@/stores/user'
import { getLogo } from '@/utils/commonFunction'

const emit = defineEmits(['cancel', 'confirm'])

const rates = ref<Record<string, string>>({})

getCoinbasePrices().then((data: any) => {
  rates.value = data
})

const user = useUserStore()
const useAccount = useAppKitAccount()
const scannableAddress = computed(() => `ethereum:${useAccount.value.address}`)

const state = ref<'qr' | 'verifying' | 'confirming'>('qr')
const totalETHRequired = new Decimal('0.001')

const amountRequired = computed(() => {
  const ethAsset = user.assets.find(
    (asset) => asset.symbol === 'ETH' && asset.abstracted,
  )
  const ethereumEthBalance =
    ethAsset?.breakdown.find(
      (b) => b.contractAddress === zeroAddress && b.chain.id === 1,
    )?.balance || '0'
  return totalETHRequired
    .minus(ethereumEthBalance)
    .mul(Decimal.pow(10, 6))
    .ceil()
    .div(Decimal.pow(10, 6))
})

if (amountRequired.value.lessThanOrEqualTo(0)) {
  state.value = 'confirming'
}

watch(amountRequired, () => {
  if (amountRequired.value.lessThanOrEqualTo(0)) {
    state.value = 'confirming'
  }
})

const verifyDeposit = async () => {
  state.value = 'verifying'
  const ethAsset = user.assets.find((asset) => asset.symbol === 'ETH')
  const balance =
    ethAsset?.breakdown.find(
      (b) => b.contractAddress === zeroAddress && b.chain.id === 1,
    )?.balance || '0'
  const newEthBalance = new Decimal(balance).div(Decimal.pow(10, 18))
  if (newEthBalance.greaterThanOrEqualTo(totalETHRequired)) {
    state.value = 'confirming'
  } else {
    state.value = 'qr'
  }
}
</script>

<template>
  <div>
    <div v-if="state === 'qr'" class="flex flex-col gap-4">
      <h3
        class="font-nohemi font-semibold text-2xl text-center px-4 text-blueGray-800 max-sm:text-xl"
      >
        Setup the Smoothest Wallet Experience
      </h3>
      <span
        class="text-blueGray-600 text-base text-center font-medium font-inter max-sm:text-xs"
        >Step 1 of 2</span
      >
      <div
        class="field rounded-xl flex flex-col gap-2 p-4 bg-background-600 border border-background-400"
      >
        <span
          class="text-blueGray-600 text-base font-normal font-inter text-start max-sm:text-xs"
          >Great! Your wallet needs {{ amountRequired.toDecimalPlaces(6) }} ETH
          more on Ethereum. Deposit it using the QR code below and click
          ‘Verify’ afterwards to complete the setup.</span
        >
        <div
          class="flex justify-center items-center bg-background-400 rounded-lg"
        >
          <div class="w-full flex justify-center rounded-lg p-4">
            <div class="relative aspect-square">
              <VueQrcode
                :value="scannableAddress"
                class="w-full aspect-square rounded-lg"
                :options="{
                  color: {
                    dark: '#1D2A31ff',
                    light: '#fdfdfdff',
                  },
                  margin: 2,
                  width: 230,
                  height: 230,
                  scale: 10,
                }"
              />
              <img
                src="@/assets/images/svg/ArcanaBg.svg?url"
                :alt="useAccount.address as string"
                class="rounded-full h-14 w-14 border-4 border-white-200 border-solid absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="flex items-center gap-4 m-px">
        <button class="button-primary w-full h-11" @click.stop="emit('cancel')">
          Back
        </button>
        <button
          class="button-secondary w-full h-11"
          @click.stop="verifyDeposit"
        >
          Verify
        </button>
      </div>
    </div>
    <div
      v-else-if="state === 'verifying'"
      class="flex flex-col gap-1 items-center justify-center"
    >
      <video
        src="@/assets/videos/new_loader.webm"
        autoplay
        loop
        muted
        class="h-32"
        @contextmenu.prevent="void 0"
      />
      <span
        class="text-2xl font-nohemi font-semibold text-blueGray-800 tabular-nums max-sm:text-xl"
        >Verifying Deposit...</span
      >
      <span
        class="text-base font-inter text-center font-medium text-blueGray-600 tabular-nums max-sm:text-xs"
        >We’re checking if your wallet has the required balance to perform the
        setup.</span
      >
    </div>
    <div v-else-if="state === 'confirming'" class="flex flex-col gap-4">
      <h3
        class="font-nohemi font-semibold text-2xl text-center px-4 text-blueGray-800 max-sm:text-xl"
      >
        Setup the Smoothest Wallet Experience
      </h3>
      <span
        class="text-blueGray-600 text-base text-center font-medium font-inter max-sm:text-xs"
        >Step 2 of 2</span
      >
      <div
        class="field rounded-xl flex flex-col gap-2 p-4 bg-background-600 border border-background-400"
      >
        <span
          class="text-blueGray-600 text-base text-start font-normal font-inter max-sm:text-xs"
          >Please confirm the following to proceed:</span
        >
        <div class="bg-background-400 rounded-lg p-4 flex flex-col gap-2">
          <span
            class="font-semibold font-inter uppercase text-base text-blueGray-600 max-sm:text-xs"
            >Transaction Details</span
          >
          <div class="flex items-center justify-between">
            <span
              class="text-base font-normal text-blueGray-700 font-inter max-sm:text-xs"
              >Chain</span
            >
            <div class="flex items-center gap-1">
              <img
                :src="getLogo('/images/logos/ethereum.svg')"
                alt="Ethereum"
                class="h-4 w-4 rounded-full"
              />
              <span
                class="text-base font-medium text-blueGray-800 font-inter max-sm:text-xs"
                >Ethereum</span
              >
            </div>
          </div>
          <div class="flex items-start justify-between">
            <div class="flex items-center gap-1">
              <span
                class="text-base font-normal text-blueGray-700 font-inter max-sm:text-xs"
                >Spend</span
              >
              <AppTooltip message="">
                <InfoIcon class="w-4 h-4 text-tertiary-200" />
              </AppTooltip>
            </div>
            <div class="flex flex-col items-end">
              <span
                class="text-base font-medium text-blueGray-800 font-inter max-sm:text-xs"
                >0.001 ETH</span
              >
              <span
                v-if="rates['ETH']"
                class="text-sm font-medium text-blueGray-600 font-inter max-sm:text-xs"
                >{{
                  new Decimal(0.001)
                    .mul(Decimal.div(1, rates['ETH']))
                    .toDecimalPlaces(2)
                }}
                USD</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="flex items-center gap-4 m-px">
        <button class="button-primary w-full h-11" @click.stop="emit('cancel')">
          Cancel
        </button>
        <button
          class="button-secondary w-full h-11"
          @click.stop="emit('confirm')"
        >
          Confirm
        </button>
      </div>
    </div>
  </div>
</template>
