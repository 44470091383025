<script lang="ts" setup>
import { useAppKitAccount } from '@reown/appkit/vue'
import { computed, onMounted, ref } from 'vue'
import FadeLoader from 'vue-spinner/src/FadeLoader.vue'

import SearchIcon from '@/assets/images/svg/Search.svg'
import ArrowRightIcon from '@/assets/images/svg/arrowMobile.svg'
import AppAssets from '@/components/layout/OverviewSetup/AppAssets.vue'
import AppHistory from '@/components/layout/OverviewSetup/AppHistory.vue'
import { useStorage } from '@/composables/activities'
import { useAllowanceStore } from '@/stores/allowance'
import { useUserStore } from '@/stores/user'
import { AllowanceDetailsTypes } from '@/types/allowanceDetailsTypes'
import { TransactionActivityData } from '@/types/txTypes'
import AppBalance from '@/pages/AppBalance.vue'
import { useRouter } from 'vue-router'

const tabs = ['Assets', 'History']
const activeTab = ref('Assets')
const user = useUserStore()
const useAccount = useAppKitAccount()
const router = useRouter()
const txLoader = ref<boolean>(true)
const allowanceStore = useAllowanceStore()
const searchTerm = ref<string>('')
const { getActivity } = useStorage()

const activityDetails = ref<TransactionActivityData[]>([])

const loadTxHistory = async () => {
  activityDetails.value = await getActivity(useAccount.value.address as string)
  txLoader.value = false
}

const setActiveTab = (tab: string) => {
  activeTab.value = tab
}

const assetsDetails = computed(() => user.assets)

const filteredActivities = computed(() => {
  return (
    activityDetails.value.length > 0 &&
    activityDetails.value
      .filter((activity) => {
        const search = searchTerm.value.toLowerCase()
        return (
          activity.type.toLowerCase().includes(search) ||
          activity.token.toLowerCase().includes(search) ||
          activity.chain.toLowerCase().includes(search) ||
          activity.abstracted.toString().includes(search) ||
          activity.amount.toString().includes(search)
        )
      })
      .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
  )
})

const areAllBalancesZero = computed((): boolean => {
  if (allowanceStore.userAllowances) {
    return allowanceStore.userAllowances.every(
      (item: AllowanceDetailsTypes) => item.allowance === 0n,
    )
  }
  return false
})

const showXPBanner = computed(() => {
  return user?.XPBalance.some((item) => item.XPResult?.tx_count !== 10)
})

const navigateTo = () => {
  router.push({ name: 'Send' })
}

onMounted(loadTxHistory)
</script>

<template>
  <div class="flex-1 p-12 overflow-auto max-sm:p-6">
    <div class="">
      <div
        class="max-sm:relative max-sm:-m-6 max-sm:bg-background-500 max-sm:mb-4"
      >
        <AppBalance title="Overview" />
      </div>

      <div
        v-if="showXPBanner && areAllBalancesZero !== true"
        class="sticky z-50 bottom-0 left-0 right-0 pb-4 bg-white-200 max-sm:hidden"
      >
        <div
          class="w-full text-blue-500 bg-orange-200 rounded-lg p-4 my-2 flex justify-between items-center cursor-pointer"
          @click.stop="navigateTo"
        >
          <div class="flex flex-col justify-start items-start gap-2">
            <div class="flex items-center w-full justify-between">
              <span
                class="font-semibold text-xl flex-wrap text-start font-nohemi leading-tight"
                >Earn 10XP for your transactions!</span
              >
            </div>
            <div
              class="text-sm font-normal text-start leading-tight font-inter"
            >
              Get rewarded for up to 100 cross-chain transactions per week.
              Limited period offer.
            </div>
          </div>

          <div class="text-black-800 text-sm font-semibold font-inter">
            Earn Now
          </div>
        </div>
      </div>

      <div
        v-if="areAllBalancesZero === true"
        class="sticky z-50 bottom-0 left-0 right-0 pb-4 bg-white-200 max-sm:hidden"
      >
        <div
          class="w-full text-blue-500 bg-orange-200 rounded-lg p-4 my-2 flex justify-between items-center cursor-pointer"
          @click.stop="allowanceStore.setAllowanceSetupModal(true)"
        >
          <div class="flex flex-col justify-start items-start gap-2">
            <div class="flex items-center w-full justify-between">
              <span
                class="font-semibold text-xl flex-wrap text-start font-nohemi leading-tight"
                >Setup chain abstraction for the smoothest experience</span
              >
            </div>
            <div
              class="text-sm font-normal text-start leading-tight font-inter"
            >
              We’re sponsoring setup on L2s for a limited time. Click here to do
              this now!
            </div>
          </div>

          <div class="text-black-800 text-sm font-semibold font-inter">
            Setup Now
          </div>
        </div>
      </div>

      <div class="w-full flex justify-between max-sm:flex-col max-sm:gap-4">
        <div
          class="w-64 flex rounded-full bg-background-500 border border-background-400"
        >
          <button
            v-for="tab in tabs"
            :key="tab"
            class="flex-1 px-6 py-3 font-nohemi text-sm font-medium rounded-full transition-all duration-300 ease-in-out"
            :class="{
              'bg-blueGray-800 text-white-100': activeTab === tab,
              'bg-transparent text-black-900 text-opacity-50':
                activeTab !== tab,
            }"
            @click.stop="setActiveTab(tab)"
          >
            {{ tab }}
          </button>
        </div>
        <div
          v-if="activeTab === 'History'"
          class="flex items-center bg-background-300 border border-background-400 rounded-lg px-2 py-2 shadow-sm w-1/4 max-w-md h-10 max-sm:w-full"
        >
          <SearchIcon
            class="w-5 h-5 stroke-blueGray-600 stroke-cap-round mr-2"
          />

          <input
            v-model="searchTerm"
            type="text"
            placeholder="Search"
            class="bg-transparent focus:outline-none w-full text-black-700"
          />
        </div>
      </div>

      <!-- Tab Content -->
      <div class="mt-6 mb-6 max-sm:mb-36">
        <div class="w-full flex justify-center">
          <FadeLoader
            :loading="user.loading || txLoader"
            color="#1D2A31"
            size="20rem"
          />
        </div>

        <AppAssets
          v-if="activeTab === 'Assets' && !user.loading"
          :assets="assetsDetails"
        />
        <AppHistory
          v-if="activeTab === 'History' && !user.loading"
          :activities="filteredActivities || []"
        />
      </div>

      <div
        class="fixed bottom-0 w-full left-0 bg-white-100 p-4 text-center text-rose-500 font-semibold font-inter text-sm hidden max-sm:block"
      >
        <div
          v-if="showXPBanner && areAllBalancesZero !== true"
          class="sticky z-50 bottom-0 left-0 right-0 pb-4 bg-white-200"
        >
          <div
            class="w-full text-blue-500 bg-orange-200 rounded-lg p-4 my-2 flex justify-between items-start cursor-pointer"
            @click.stop="navigateTo"
          >
            <div class="flex flex-col justify-start items-start gap-2">
              <div class="flex items-center w-full justify-between">
                <span
                  class="font-semibold text-xl flex-wrap text-start font-nohemi leading-tight max-sm:text-base"
                  >Earn 10XP for your transactions!</span
                >
              </div>
              <div
                class="text-sm font-normal text-start leading-tight font-inter max-sm:text-0.625rem"
              >
                Get rewarded for up to 100 cross-chain transactions per week.
                Limited period offer.
              </div>
            </div>

            <div
              class="text-black-800 text-sm font-semibold font-inter max-sm:text-0.625rem"
            >
              <ArrowRightIcon class="h-4 w-4 stroke-blue-500" />
            </div>
          </div>
        </div>

        <div
          v-if="areAllBalancesZero === true"
          class="sticky z-50 bottom-0 left-0 right-0 pb-4 bg-white-200"
        >
          <div
            class="w-full text-blue-500 bg-orange-200 rounded-lg p-4 my-2 flex justify-between items-center cursor-pointer"
            @click.stop="allowanceStore.setAllowanceSetupModal(true)"
          >
            <div class="flex flex-col justify-start items-start gap-2">
              <div class="flex items-center w-full justify-between">
                <span
                  class="font-semibold text-xl flex-wrap text-start font-nohemi leading-tight max-sm:text-base"
                  >Setup chain abstraction for the smoothest experience</span
                >
              </div>
              <div
                class="text-sm font-normal text-start leading-tight font-inter max-sm:text-0.625rem"
              >
                We’re sponsoring setup on L2s for a limited time. Click here to
                do this now!
              </div>
            </div>

            <div
              class="text-black-800 text-sm font-semibold font-inter max-sm:text-0.625rem"
            >
              <ArrowRightIcon class="h-4 w-4 stroke-blue-500" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
