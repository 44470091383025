<script setup lang="ts">
const emit = defineEmits(['retry'])
</script>

<template>
  <div class="flex flex-col gap-4">
    <div class="flex items-center justify-center">
      <div class="flex flex-col gap-1 items-center justify-center">
        <video
          src="@/assets/videos/new_error.webm"
          autoplay
          muted
          class="h-32 relative animate-fadeIn"
          @contextmenu.prevent="void 0"
        />
      </div>
    </div>
    <div class="flex flex-col gap-4">
      <span
        class="text-2xl font-nohemi text-center font-semibold text-blueGray-800 tabular-nums max-sm:text-xl"
        >Oops!</span
      >
      <span
        class="text-base font-inter text-center font-medium text-blueGray-600 tabular-nums max-sm:text-xs"
      >
        Something went wrong during the setup. You could click on the button
        below to try the setup again or close this window to skip it for now.
      </span>
    </div>
    <div class="m-px">
      <button class="button-secondary w-full h-11" @click.stop="emit('retry')">
        Retry
      </button>
    </div>
  </div>
</template>
