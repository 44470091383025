import { TransactionActivityData } from '@/types/txTypes'
import { devLogger } from '@/utils/devLogger'

export const useStorage = () => {
  const setActivity = (
    userWalletAddress: string,
    transactions: TransactionActivityData,
  ): void => {
    const key = `transactions_${userWalletAddress}`
    const activity = getActivity(userWalletAddress)
    if (activity === null) {
      localStorage.setItem(key, JSON.stringify(transactions))
    } else {
      localStorage.setItem(key, JSON.stringify([...activity, transactions]))
    }
  }

  const getActivity = (
    userWalletAddress: string,
  ): TransactionActivityData[] => {
    const key = `transactions_${userWalletAddress}`
    const storedTransactions = localStorage.getItem(key)
    console.log(storedTransactions, 'storedTransactions')

    if (storedTransactions) {
      try {
        return Array.isArray(JSON.parse(storedTransactions))
          ? (JSON.parse(storedTransactions) as TransactionActivityData[])
          : ([JSON.parse(storedTransactions)] as TransactionActivityData[])
      } catch (error) {
        devLogger.log('Failed to parse transaction data:', error)
      }
    }

    return []
  }

  return {
    setActivity,
    getActivity,
  }
}
