<script lang="ts" setup>
import { useAppKitAccount } from '@reown/appkit/vue'
import { RouterView } from 'vue-router'

import { useLoader } from '@/stores/loader'

import AppHeader from '@/components/layout/AppHeader.vue'
import AppSidebar from '@/components/layout/AppSidebar.vue'
import ChainAbstraction from '@/pages/ChainAbstraction.vue'
import FadeLoader from 'vue-spinner/src/FadeLoader.vue'
import { ref } from 'vue'

const useAccount = useAppKitAccount()
const loader = useLoader()
const isSidebarOpen = ref(false)

const toggleSidebar = () => {
  isSidebarOpen.value = !isSidebarOpen.value
}
</script>

<template>
  <AppHeader :isSidebarOpen="isSidebarOpen" @toggleSidebar="toggleSidebar" />
  <ChainAbstraction />
  <RouterView v-slot="{ Component }">
    <div :class="useAccount.isConnected && 'flex overflow-auto'">
      <AppSidebar
        v-if="useAccount.isConnected"
        :isSidebarOpen="isSidebarOpen"
        @toggleSidebar="toggleSidebar"
      />

      <div
        class="flex justify-center w-full mt-10"
        v-if="loader.loader && useAccount.isConnected"
      >
        <FadeLoader :loading="loader.loader" color="#1D2A31" size="58rem" />
      </div>

      <Component :is="Component" />
    </div>
  </RouterView>
</template>
