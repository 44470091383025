<script lang="ts" setup>
import { useRouter } from 'vue-router'

import { useAllowanceStore } from '@/stores/allowance'
import { AllowanceDetailsTypes } from '@/types/allowanceDetailsTypes'
import {
  getChainDetails,
  getLogo,
  isMaxAllowance,
} from '@/utils/commonFunction'
import { symbolToLogo } from '@/utils/getLogoFromSymbol'
import Plus from '@/assets/images/svg/plus.svg'
import ChevronDownIcon from '@/assets/images/svg/ChevronDown.svg'
import { computed } from 'vue'

const props = defineProps<{
  allowanceDetails: AllowanceDetailsTypes[]
}>()

const router = useRouter()
const allowanceStore = useAllowanceStore()

const selectAllowanceData = (asset: AllowanceDetailsTypes) => {
  allowanceStore.setAllowance(asset)
  router.push({ name: 'AllowanceSetup' })
}

const areAllBalancesZero = computed((): boolean => {
  if (allowanceStore.userAllowances) {
    return allowanceStore.userAllowances.every(
      (item: AllowanceDetailsTypes) => item.allowance === 0n,
    )
  }
  return false
})
</script>

<template>
  <div
    v-if="props.allowanceDetails.length"
    class="overflow-x-auto bg-background-600 rounded-2xl border-t border-l border-r border-background-400 max-md:hidden"
  >
    <table
      class="text-black md:max-lg:block overflow-x-auto border-collapse w-full"
    >
      <tbody>
        <tr
          v-for="asset in props.allowanceDetails"
          :key="asset.chainID"
          class="transition-colors duration-200 hover:bg-background-700 border-b border-background-400 font-inter group"
        >
          <td class="px-4 py-2 text-left">
            <div class="flex items-center h-14 space-x-4">
              <div class="relative isolate">
                <img
                  :src="getLogo(symbolToLogo[asset.token])"
                  class="h-7 w-7 rounded-full bg-white-100"
                  alt="Logo"
                />
                <img
                  :src="getLogo(getChainDetails(asset.chainID)?.custom.icon)"
                  class="absolute z-50 rounded-full border border-solid border-white-100 h-3.5 w-3.5 -bottom-1 -right-1"
                  alt="Logo"
                />
              </div>
              <div class="flex items-center gap-1">
                <span
                  class="inline-block align-middle text-ellipsis overflow-hidden text-base font-medium leading-5 text-blueGray-800"
                  >{{ asset.token }}
                </span>
                <div
                  class="inline-block align-middle text-ellipsis overflow-hidden text-xs font-normal leading-4 text-blueGray-600"
                >
                  {{ getChainDetails(asset.chainID)?.name }}
                </div>
              </div>
            </div>
          </td>
          <td class="px-4 py-2 text-center">
            <div
              class="flex items-center justify-center gap-2 font-inter text-base font-normal leading-5 text-black-700"
            >
              {{
                isMaxAllowance(asset.allowance) ? 'Unlimited' : asset.allowance
              }}
            </div>
          </td>
          <td class="px-4 py-2 text-right w-1/3">
            <div class="flex items-center justify-end gap-2">
              <button
                class="button-primary w-32 h-11 opacity-0 group-hover:opacity-100 group-hover:visible transition-opacity duration-300"
                @click.stop="selectAllowanceData(asset)"
              >
                Edit
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div
    v-if="props.allowanceDetails.length"
    class="relative min-h-screen hidden max-md:block"
  >
    <div
      v-for="(asset, index) in props.allowanceDetails"
      :key="index"
      class="mt-3"
      :class="index === props.allowanceDetails.length - 1 ? 'mb-20' : ''"
    >
      <div
        class="flex justify-between items-center bg-background-600 p-4 border border-background-1000 rounded-2xl hover:bg-gray-100 transition"
      >
        <div class="flex items-center gap-3">
          <div class="relative isolate">
            <img
              :src="getLogo(symbolToLogo[asset.token])"
              class="h-7 w-7 rounded-full bg-white-100"
              alt="Logo"
            />
            <img
              :src="getLogo(getChainDetails(asset.chainID)?.custom.icon)"
              class="absolute z-50 rounded-full border border-solid border-white-100 h-3.5 w-3.5 -bottom-1 -right-1"
              alt="Logo"
            />
          </div>
          <div class="flex items-center gap-1">
            <span
              class="inline-block align-middle text-ellipsis overflow-hidden text-sm font-medium font-inter leading-5 text-blueGray-800"
              >{{ asset.token }}
            </span>
            <div
              class="inline-block align-middle text-ellipsis overflow-hidden text-0.625rem font-normal font-inter leading-4 text-blueGray-600"
            >
              {{ getChainDetails(asset.chainID)?.name }}
            </div>
          </div>
        </div>
        <div class="flex items-center gap-4">
          <div
            class="flex items-center justify-center gap-2 font-inter text-xs font-semibold leading-5 text-blueGray-800"
          >
            {{
              isMaxAllowance(asset.allowance) ? 'Unlimited' : asset.allowance
            }}
          </div>
          <ChevronDownIcon
            class="w-5 h-5 stroke-blueGray-800 -rotate-90"
            @click.stop="selectAllowanceData(asset)"
          />
        </div>
      </div>
    </div>

    <div
      class="fixed bottom-0 w-full left-0 bg-white-100 p-4 text-center text-rose-500 font-semibold font-inter text-sm"
    >
      <p
        class="flex justify-center items-center gap-2 cursor-pointer"
        @click.stop="router.push({ name: 'AllowanceSetup' })"
      >
        <Plus />
        Set Token Allowance
      </p>
      <p
        v-if="areAllBalancesZero === true"
        class="flex justify-center items-center gap-2 cursor-pointer mt-4"
        @click.stop="allowanceStore.setAllowanceSetupModal(true)"
      >
        <Plus /> Setup Chain Abstraction
      </p>
    </div>
  </div>

  <div
    v-else
    class="flex flex-col gap-4 justify-center items-center h-20 field rounded-md bg-background-400 p-1 text-black-900 text-base font-inter font-semibold"
  >
    No allowances
  </div>
</template>
