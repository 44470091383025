import { getAccount } from '@wagmi/core'

import { wagmiAdapter } from '@/composables/config'
import { EthereumProvider } from '@/types/providerTypes'

export const useWalletProvider = async (): Promise<EthereumProvider> => {
  const account = await getAccount(wagmiAdapter.wagmiConfig)

  const provider =
    account?.connector?.getProvider() as unknown as EthereumProvider

  return provider
}
