import axios from 'axios'

export const getGasFromFaucet = async (
  address: string,
  chainIds?: string[],
) => {
  const response = await axios.post(import.meta.env.VITE_ARCANA_FAUCET_URL, {
    address,
    chainIds,
  })
  return response.data
}
