<script lang="ts" setup>
import Decimal from 'decimal.js'
import { ref } from 'vue'

import ArrowDownIcon from '@/assets/images/svg/ArrowDown.svg'
import ArrowDownActivityIcon from '@/assets/images/svg/ArrowDownActivity.svg'
import ArrowSideIcon from '@/assets/images/svg/ArrowUpActivity.svg'
import InfoIcon from '@/assets/images/svg/InfoCircle.svg'
import AppTooltip from '@/components/shared/AppTooltip.vue'
import { getCoinbasePrices } from '@/services/coinbase'
import type { TransactionActivityData } from '@/types/txTypes'
import { formatDate, formatTime, truncateString } from '@/utils/commonFunction'

const props = defineProps<{
  activities: TransactionActivityData[]
}>()

const rates = ref<Record<string, string>>({})

getCoinbasePrices().then((data: any) => {
  rates.value = data
})
</script>

<template>
  <div
    v-if="props.activities.length"
    class="overflow-x-auto bg-background-600 rounded-2xl border-t border-l border-r border-background-400 max-md:hidden"
  >
    <table
      class="text-black md:max-lg:block overflow-x-auto border-collapse w-full"
    >
      <thead class="border-b border-b-background-400">
        <tr
          class="bg-background-400 text-blueGray-600 font-inter text-xs font-semibold leading-5 uppercase"
        >
          <th class="px-4 py-2">
            <div class="flex justify-start items-center gap-2">
              <span>Action</span>
              <ArrowDownIcon />
            </div>
          </th>
          <th class="px-4 py-2">
            <div class="flex justify-center items-center gap-2">
              <span>Chain</span>
              <ArrowDownIcon />
            </div>
          </th>
          <th class="px-4 py-2">
            <div class="flex justify-center items-center gap-2">
              <span>Timestamp</span>
              <ArrowDownIcon />
            </div>
          </th>
          <th class="px-4 py-2">
            <div class="flex justify-center items-center gap-2">
              <span>Destination/Source</span>
              <ArrowDownIcon />
            </div>
          </th>
          <th class="px-4 py-2">
            <div class="flex justify-end items-center gap-2">
              <span>Transaction amount</span>
              <ArrowDownIcon />
            </div>
          </th>
        </tr>
      </thead>
      <tbody class="font-inter">
        <tr
          v-for="(activity, index) in props.activities"
          :key="index"
          class="transition-colors duration-200 hover:bg-background-700 border-b border-background-400"
        >
          <td class="px-4 py-2 text-left">
            <div class="flex items-center gap-4 max-xl:w-64">
              <ArrowSideIcon v-if="activity.type === 'Send'" class="h-7 w-7" />
              <ArrowDownActivityIcon v-else class="h-7 w-7" />

              <div class="flex items-center gap-4">
                <span
                  class="inline-block align-middle max-w-6ch text-ellipsis overflow-hidden text-base font-normal leading-5 text-black-900"
                  >{{ activity.type }}</span
                >
                <div
                  v-if="activity.abstracted"
                  class="text-rose-500 text-0.625rem font-normal flex items-center gap-1 p-2 rounded-full bg-rose-200"
                >
                  Chain Abstracted
                  <AppTooltip message="Chain Abstracted">
                    <InfoIcon
                      class="h-3 w-3 stroke-rose-500 stroke-cap-round"
                    />
                  </AppTooltip>
                </div>
              </div>
            </div>
          </td>
          <td class="px-4 py-2 text-center">
            <div class="flex justify-start items-center gap-4 max-xl:w-40">
              <img
                :src="activity.chainIcon"
                alt="Logo"
                class="h-7 w-7 rounded-full bg-white-100"
              />
              <span class="text-base font-normal leading-5 text-black-900">{{
                activity.chain
              }}</span>
            </div>
          </td>
          <td class="px-4 py-2 text-center">
            <div
              class="flex items-center justify-center gap-2 text-base font-normal leading-5 text-black-900 max-xl:w-40"
            >
              <span>
                {{ formatDate(activity.date) }}
              </span>
              <span> {{ formatTime(activity.date) }}</span>
            </div>
          </td>
          <td class="px-4 py-2 text-center">
            <AppTooltip :message="activity.address">
              {{ truncateString(activity.address) }}
            </AppTooltip>
          </td>
          <td class="px-4 py-2 text-right">
            <div
              class="flex flex-col items-end gap-2 text-base font-normal leading-5 text-black-900 max-xl:w-40"
            >
              <span
                >{{ activity.amount }} <span>{{ activity.token }}</span></span
              >
              <span class="text-xs font-normal leading-3 text-blueGray-700"
                >~{{
                  new Decimal(activity.amount || 0)
                    .mul(
                      rates[activity.token]
                        ? Decimal.div(1, rates[activity.token])
                        : new Decimal(0),
                    )
                    .toDecimalPlaces(6)
                }}
                USD</span
              >
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div
    v-if="props.activities.length"
    class="flex-col gap-4 items-center hidden max-md:flex"
  >
    <div
      class="flex items-center justify-between bg-background-500 p-4 rounded-lg border border-black-1000 w-full"
      v-for="(activity, index) in props.activities"
      :key="index"
    >
      <div class="flex justify-between items-start w-full">
        <div class="flex items-center gap-3">
          <div class="w-10 h-10 flex items-center justify-center rounded-full">
            <ArrowSideIcon v-if="activity.type === 'Send'" class="h-7 w-7" />
            <ArrowDownActivityIcon v-else class="h-7 w-7" />
          </div>

          <div>
            <div class="flex items-center gap-2">
              <span
                class="font-inter inline-block align-middle max-w-6ch text-ellipsis overflow-hidden text-base font-semibold leading-5 text-blueGray-800"
                >{{ activity.type }}</span
              >
              <span
                class="text-rose-500 text-0.625rem font-semibold flex items-center gap-1 p-2 rounded-full bg-rose-200 font-inter max-sm:0.5rem"
                v-if="activity.abstracted"
              >
                CA
                <AppTooltip message="Chain Abstracted">
                  <InfoIcon class="h-3 w-3 stroke-rose-500 stroke-cap-round" />
                </AppTooltip>
              </span>
            </div>
            <span class="text-blueGray-700 text-xs font-inter font-normal"
              ><AppTooltip :message="activity.address">
                {{ truncateString(activity.address) }}
              </AppTooltip></span
            >
            <div class="text-xs text-gray-400 mt-1">
              <div
                class="flex items-center justify-start gap-2 text-0.625rem font-normal font-inter leading-5 text-blueGray-700"
              >
                <span>
                  {{ formatDate(activity.date) }}
                </span>
                <span> {{ formatTime(activity.date) }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-col items-end gap-2">
          <div class="flex items-center gap-2">
            <div class="relative isolate">
              <img
                :src="activity.tokenIcon"
                class="h-7 w-7 rounded-full bg-white-100"
                alt="Logo"
              />
              <img
                :src="activity.chainIcon"
                class="absolute z-50 rounded-full border border-solid border-white-100 h-3.5 w-3.5 -bottom-1 -right-1"
                alt="Logo"
              />
            </div>
            <span class="text-blueGray-800 font-inter font-semibold text-base"
              ><span
                >{{ new Decimal(activity.amount || 0).toDecimalPlaces(6) }}
                <span>{{ activity.token }}</span></span
              ></span
            >
          </div>

          <span
            class="font-inter text-xs font-normal leading-3 text-blueGray-700"
            >~{{
              new Decimal(activity.amount || 0)
                .mul(
                  rates[activity.token]
                    ? Decimal.div(1, rates[activity.token])
                    : new Decimal(0),
                )
                .toDecimalPlaces(4)
            }}
            USD</span
          >
        </div>
      </div>
    </div>
  </div>

  <div
    v-else
    class="flex flex-col gap-4 justify-center items-center h-20 field rounded-md bg-background-400 p-1 text-black-900 text-base font-inter font-semibold"
  >
    No tokens yet
  </div>
</template>
