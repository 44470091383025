<script lang="ts" setup>
import { Accordion } from '@ark-ui/vue'
import Decimal from 'decimal.js'
import readable from 'readable-numbers'
import { useRouter } from 'vue-router'

import ChevronDownIcon from '@/assets/images/svg/ChevronDown.svg'
import InfoIcon from '@/assets/images/svg/InfoCircle.svg'
import AppTooltip from '@/components/shared/AppTooltip.vue'
import { useTokenStore } from '@/stores/token'
import type { Asset, Breakdown } from '@/types/balanceTypes'
import { formatNumber } from '@/utils/commonFunction'
import { ref } from 'vue'

const props = defineProps<{
  assets: Asset[]
}>()

const router = useRouter()
const tokenStore = useTokenStore()

const selectToken = (
  token: Asset,
  type: 'transfer' | 'bridge' | 'consolidate',
) => {
  tokenStore.setToken(token)

  switch (type) {
    case 'transfer':
      router.push({ name: 'Send' })
      break
    case 'bridge':
      router.push({ name: 'Bridge' })
      break
    case 'consolidate':
      router.push({ name: 'Consolidate' })
      break
  }
}

const getBreakdownImageArray = (breakdown: Breakdown[]) => {
  const length = breakdown.length
  const spliceLength = length > 3 ? 2 : length
  return [...breakdown].slice(0, spliceLength)
}

const expanded = ref(Array(props.assets.length).fill(false))

const toggleExpand = (index: number) => {
  expanded.value[index] = !expanded.value[index]
}
</script>

<template>
  <div
    v-if="props.assets.length"
    class="overflow-x-auto bg-background-600 rounded-2xl border-t border-l border-r border-background-400 max-md:hidden"
  >
    <table
      class="text-black md:max-lg:block overflow-x-auto border-collapse w-full"
    >
      <thead class="border-b border-b-background-400">
        <tr
          class="bg-background-400 text-blueGray-600 font-inter text-xs font-semibold leading-5 uppercase"
        >
          <th class="px-4 py-2 text-left">Asset</th>
          <th class="px-4 py-2 text-start pl-10">Balance</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(asset, i) in props.assets"
          :key="i"
          class="transition-colors duration-200 hover:bg-background-700 border-b border-background-400 group"
        >
          <td class="px-4 py-2 text-left w-4/12">
            <Accordion.Root
              v-if="props.assets.length"
              v-auto-animate
              collapsible
              multiple
              class="flex flex-col gap-4 overflow-y-auto field p-3 max-xl:w-64"
            >
              <Accordion.Item :value="JSON.stringify(asset.breakdown)">
                <div class="flex items-center justify-between">
                  <div class="flex items-center gap-1">
                    <div class="flex items-center gap-4">
                      <div class="relative isolate">
                        <img
                          :src="asset.icon"
                          class="h-7 w-7 rounded-full bg-white-100"
                          alt="Logo"
                        />
                        <img
                          v-for="(b, index) in getBreakdownImageArray(
                            asset.breakdown,
                          )"
                          :key="b.chain.id"
                          :src="b.chain.logo"
                          :class="{
                            'absolute rounded-full border border-solid border-white-100': true,
                            'h-2.5 w-2.5': asset.breakdown.length > 1,
                            'h-3.5 w-3.5 -bottom-1 -right-1':
                              asset.breakdown.length === 1,
                            'right-0 -bottom-1':
                              asset.breakdown.length > 1 && index === 0,
                            'bottom-0 -right-1':
                              asset.breakdown.length > 1 && index === 1,
                            'bottom-1.5 -right-1.5':
                              asset.breakdown.length > 1 && index === 2,
                          }"
                          :style="{
                            zIndex: `${index + 1}`,
                          }"
                          alt="Logo"
                        />
                        <div
                          v-if="asset.breakdown.length > 3"
                          :class="{
                            'absolute rounded-full border border-solid bg-blue-500 border-white-100 h-2.5 w-2.5 bottom-1.5 -right-1.5 flex items-center justify-center': true,
                          }"
                          :style="{
                            zIndex: asset.breakdown.length,
                          }"
                        >
                          <span
                            v-if="asset.breakdown.length > 11"
                            class="text-0.25rem font-semibold tabular-nums text-white-200"
                          >
                            +9
                          </span>
                          <span
                            v-else
                            class="text-0.25rem font-semibold tabular-nums text-white-200"
                            >+{{ asset.breakdown.length - 2 }}</span
                          >
                        </div>
                      </div>
                      <div class="flex flex-col">
                        <div class="flex items-center gap-1">
                          <span
                            class="inline-block align-middle max-w-6ch text-ellipsis overflow-hidden font-inter text-base font-normal leading-5 text-black-900"
                            >{{ asset.symbol }}</span
                          >
                          <div
                            v-if="asset.abstracted"
                            class="text-rose-500 text-0.625rem font-inter font-normal flex items-center gap-1 p-2 rounded-full bg-rose-200"
                          >
                            Chain Abstracted
                            <AppTooltip message="Chain Abstracted">
                              <InfoIcon
                                class="h-3 w-3 stroke-rose-500 stroke-cap-round"
                              />
                            </AppTooltip>
                          </div>
                        </div>
                        <Accordion.ItemTrigger class="flex items-center gap-1">
                          <span
                            class="text-xs font-inter font-normal text-blue-500"
                            >{{ asset.breakdown.length }} chain{{
                              asset.breakdown.length > 1 ? 's' : ''
                            }}</span
                          >
                          <Accordion.ItemIndicator>
                            <ChevronDownIcon class="h-4 w-4 stroke-blue-500" />
                          </Accordion.ItemIndicator>
                        </Accordion.ItemTrigger>
                      </div>
                    </div>
                  </div>
                </div>
                <Accordion.ItemContent>
                  <div class="pt-3">
                    <div
                      class="bg-white-100 bg-opacity-75 py-4 px-2 rounded-lg"
                    >
                      <div class="flex flex-col gap-4">
                        <div
                          v-for="token in asset.breakdown"
                          :key="token.network"
                          class="flex items-center justify-between font-inter text-base font-normal leading-5 text-black-700"
                        >
                          <span class="flex items-center gap-3">
                            <div class="relative isolate">
                              <img
                                :src="asset.icon"
                                class="relative z-10 h-5 w-5 rounded-full bg-white-100"
                                alt="Logo"
                              />
                              <img
                                :src="token.chain.logo"
                                class="absolute h-3 w-3 z-20 rounded-full -bottom-3px -right-3px border border-solid border-white-100"
                                alt="Logo"
                              />
                            </div>
                            <span class="text-xs"
                              ><span
                                class="inline-block align-middle max-w-6ch text-ellipsis overflow-hidden"
                                >{{ asset.symbol }}</span
                              >
                              ({{ token.chain.name }})</span
                            >
                          </span>
                          <div
                            class="font-inter text-base font-normal leading-5 text-black-700"
                          >
                            <AppTooltip
                              :message="`$${formatNumber(
                                token.balanceInFiat,
                              )} (${new Decimal(token.balance)} ${asset.symbol})`"
                            >
                              <span class="text-xs flex items-center gap-1"
                                >{{
                                  readable(
                                    new Decimal(token.balance)
                                      .toDecimalPlaces(4)
                                      .toNumber(),
                                  )
                                }}
                                <span
                                  class="inline-block align-middle max-w-6ch text-ellipsis overflow-x-hidden"
                                  >{{ asset.symbol }}</span
                                ></span
                              >
                            </AppTooltip>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Accordion.ItemContent>
              </Accordion.Item>
            </Accordion.Root>
          </td>
          <td
            class="px-4 py-2 text-right w-full flex items-center justify-end mt-3"
          >
            <div class="w-full flex justify-between items-center">
              <AppTooltip
                :message="`$${formatNumber(asset.balanceInFiat)} (${new Decimal(
                  asset.balance,
                )} ${asset.symbol})`"
              >
                <div
                  class="flex gap-1 font-inter text-base font-normal leading-5 text-black-700"
                >
                  {{
                    readable(
                      new Decimal(asset.balance).toDecimalPlaces(4).toNumber(),
                      2,
                    )
                  }}
                  <span
                    class="inline-block align-middle max-w-6ch text-ellipsis overflow-hidden"
                    >{{ asset.symbol }}</span
                  >
                </div>
              </AppTooltip>
              <div class="flex items-center justify-end gap-2">
                <button
                  class="button-primary w-32 h-11 opacity-0 group-hover:opacity-100 group-hover:visible transition-opacity duration-300"
                  @click.stop="selectToken(asset, 'transfer')"
                >
                  Send
                </button>
                <button
                  class="button-primary w-32 h-11 opacity-0 group-hover:opacity-100 group-hover:visible transition-opacity duration-300"
                  @click.stop="selectToken(asset, 'bridge')"
                >
                  Bridge
                </button>
                <!-- <button
                  class="button-primary w-32 h-11 opacity-0 group-hover:opacity-100 group-hover:visible transition-opacity duration-300"
                  @click.stop="selectToken(asset, 'consolidate')"
                >
                  Consolidate
                </button> -->
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div
    v-if="props.assets.length"
    class="overflow-x-auto bg-background-600 rounded-2xl border-t border-l border-r border-background-400 hidden max-md:block"
  >
    <table
      class="text-black md:max-lg:block overflow-x-auto border-collapse w-full"
    >
      <thead class="border-b border-b-background-400">
        <tr
          class="bg-background-400 text-blueGray-600 font-inter text-xs font-semibold leading-5 uppercase"
        >
          <th class="px-4 py-2 flex justify-between items-center">
            <div>Asset</div>
            <div class="w-32 text-left">Balance</div>
          </th>
        </tr>
      </thead>

      <tbody>
        <tr
          v-for="(asset, i) in props.assets"
          :key="i"
          class="transition-colors duration-200 hover:bg-background-700 border-b border-background-400 group"
        >
          <td class="relative px-2 py-2 text-left">
            <Accordion.Root
              v-if="props.assets.length"
              v-auto-animate
              collapsible
              multiple
              class="flex flex-col gap-4 field p-2"
            >
              <Accordion.Item :value="JSON.stringify(asset.breakdown)">
                <div class="flex items-center justify-between">
                  <div class="flex items-center gap-1">
                    <div class="flex items-center gap-4">
                      <div class="relative isolate">
                        <img
                          :src="asset.icon"
                          class="h-7 w-7 rounded-full bg-white-100"
                          alt="Logo"
                        />
                        <img
                          v-for="(b, index) in getBreakdownImageArray(
                            asset.breakdown,
                          )"
                          :key="b.chain.id"
                          :src="b.chain.logo"
                          :class="{
                            'absolute rounded-full border border-solid border-white-100': true,
                            'h-2.5 w-2.5': asset.breakdown.length > 1,
                            'h-3.5 w-3.5 -bottom-1 -right-1':
                              asset.breakdown.length === 1,
                            'right-0 -bottom-1':
                              asset.breakdown.length > 1 && index === 0,
                            'bottom-0 -right-1':
                              asset.breakdown.length > 1 && index === 1,
                            'bottom-1.5 -right-1.5':
                              asset.breakdown.length > 1 && index === 2,
                          }"
                          :style="{
                            zIndex: `${index + 1}`,
                          }"
                          alt="Logo"
                        />
                        <div
                          v-if="asset.breakdown.length > 3"
                          :class="{
                            'absolute rounded-full border border-solid bg-blue-500 border-white-100 h-2.5 w-2.5 bottom-1.5 -right-1.5 flex items-center justify-center': true,
                          }"
                          :style="{
                            zIndex: asset.breakdown.length,
                          }"
                        >
                          <span
                            v-if="asset.breakdown.length > 11"
                            class="text-0.25rem font-semibold tabular-nums text-white-200"
                          >
                            +9
                          </span>
                          <span
                            v-else
                            class="text-0.25rem font-semibold tabular-nums text-white-200"
                            >+{{ asset.breakdown.length - 2 }}</span
                          >
                        </div>
                      </div>
                      <div class="flex flex-col">
                        <div class="flex items-center gap-1">
                          <span
                            class="inline-block align-middle max-w-6ch text-ellipsis overflow-hidden font-inter text-base font-normal leading-5 text-black-900"
                            >{{ asset.symbol }}</span
                          >
                          <div
                            v-if="asset.abstracted"
                            class="text-rose-500 text-0.625rem font-inter font-normal flex items-center gap-1 p-2 rounded-full bg-rose-200"
                          >
                            CA
                            <AppTooltip message="Chain Abstracted">
                              <InfoIcon
                                class="h-3 w-3 stroke-rose-500 stroke-cap-round"
                              />
                            </AppTooltip>
                          </div>
                        </div>
                        <Accordion.ItemTrigger class="flex items-center gap-1">
                          <span
                            class="text-xs font-inter font-normal text-blue-500"
                            >{{ asset.breakdown.length }} chain{{
                              asset.breakdown.length > 1 ? 's' : ''
                            }}</span
                          >
                          <Accordion.ItemIndicator>
                            <ChevronDownIcon class="h-4 w-4 stroke-blue-500" />
                          </Accordion.ItemIndicator>
                        </Accordion.ItemTrigger>
                      </div>
                    </div>
                  </div>

                  <div
                    class="flex justify-start gap-2 items-center font-medium w-32"
                  >
                    <AppTooltip
                      :message="`$${formatNumber(asset.balanceInFiat)} (${new Decimal(
                        asset.balance,
                      )} ${asset.symbol})`"
                    >
                      <div
                        class="flex gap-1 font-inter text-base font-medium leading-5 text-black-700"
                      >
                        {{
                          readable(
                            new Decimal(asset.balance)
                              .toDecimalPlaces(4)
                              .toNumber(),
                            2,
                          )
                        }}
                        <span
                          class="inline-block align-middle max-w-6ch text-ellipsis overflow-hidden"
                          >{{ asset.symbol }}</span
                        >
                      </div>
                    </AppTooltip>
                    <span
                      class="text-xs text-blue-500 block"
                      @click="toggleExpand(i)"
                    >
                      <ChevronDownIcon
                        v-if="expanded[i]"
                        class="h-5 w-5 stroke-maroon-800 rotate-180"
                      />
                      <ChevronDownIcon
                        v-else
                        class="h-5 w-5 stroke-maroon-800"
                      />
                    </span>
                  </div>
                </div>
                <Accordion.ItemContent>
                  <div class="pt-3">
                    <div
                      class="bg-blueGray-1200 bg-opacity-75 py-4 px-2 rounded-lg"
                    >
                      <div class="flex flex-col gap-4">
                        <div
                          v-for="token in asset.breakdown"
                          :key="token.network"
                          class="flex items-center justify-between font-inter text-base font-normal leading-5 text-black-700 gap-2"
                        >
                          <span class="flex items-center gap-3">
                            <div class="relative isolate">
                              <img
                                :src="asset.icon"
                                class="relative z-10 h-5 w-5 rounded-full bg-white-100"
                                alt="Logo"
                              />
                              <img
                                :src="token.chain.logo"
                                class="absolute h-3 w-3 z-20 rounded-full -bottom-3px -right-3px border border-solid border-white-100"
                                alt="Logo"
                              />
                            </div>
                            <span
                              class="text-xs font-normal font-inter text-blueGray-800"
                              ><span
                                class="inline-block align-middle max-w-6ch text-ellipsis overflow-hidden"
                                >{{ asset.symbol }}</span
                              >
                              ({{ token.chain.name }})</span
                            >
                          </span>
                          <div
                            class="font-inter text-xs font-normal text-blueGray-800"
                          >
                            <AppTooltip
                              :message="`$${formatNumber(
                                token.balanceInFiat,
                              )} (${new Decimal(token.balance)} ${asset.symbol})`"
                            >
                              <span class="text-xs flex items-center gap-1"
                                >{{
                                  readable(
                                    new Decimal(token.balance)
                                      .toDecimalPlaces(4)
                                      .toNumber(),
                                  )
                                }}
                                <span
                                  class="inline-block align-middle max-w-6ch text-ellipsis overflow-x-hidden"
                                  >{{ asset.symbol }}</span
                                ></span
                              >
                            </AppTooltip>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Accordion.ItemContent>

                <div v-if="expanded[i]" class="p-3 flex justify-between gap-2">
                  <button
                    class="button-primary w-6/12 h-11 transition-opacity duration-300"
                    @click.stop="selectToken(asset, 'transfer')"
                  >
                    Send
                  </button>
                  <button
                    class="button-primary w-6/12 h-11 transition-opacity duration-300"
                    @click.stop="selectToken(asset, 'bridge')"
                  >
                    Bridge
                  </button>
                </div>
              </Accordion.Item>
            </Accordion.Root>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div
    v-else
    class="flex flex-col gap-4 justify-center items-center h-20 field rounded-md bg-background-400 p-1 text-black-900 text-base font-inter font-semibold"
  >
    No tokens yet
  </div>
</template>
