import { defineStore } from 'pinia'
import { ref } from 'vue'

import { Asset } from '@/types/balanceTypes'
import { XPBalance } from '@/types/xpBalanceTypes'
import { getXPForAddress } from '@/services/arcanaNetwork'

export const useUserStore = defineStore('user', () => {
  const isWalletConnected = ref<boolean>(false)
  const walletAddress = ref<string>('')
  const balanceInFiat = ref<number>(0)
  const loading = ref<boolean>(true)
  const assets = ref<Asset[]>([])
  const XPBalance = ref<XPBalance[]>([])

  const setAsset = (newAssets: Asset[]) => {
    assets.value = newAssets
    loading.value = false
  }

  const setLoading = (newLoading: boolean) => {
    loading.value = newLoading
  }

  const fetchXPBalance = async (address: `0x${string}`) => {
    if (address === '0x') {
      return []
    }
    const XPBalanceResult = await getXPForAddress('ETHEREUM', address)
    console.log(XPBalanceResult)

    const XPBalanceArray = address && XPBalanceResult ? [XPBalanceResult] : []
    XPBalance.value = XPBalanceArray
  }

  return {
    isWalletConnected,
    walletAddress,
    balanceInFiat,
    assets,
    setAsset,
    loading,
    setLoading,
    XPBalance,
    fetchXPBalance,
  }
})
