<script lang="ts" setup>
import { useAppKitAccount } from '@reown/appkit/vue'

import CopyButton from '@/components/shared/CopyButton.vue'
import { useUserStore } from '@/stores/user'
import { formatNumber, truncateString } from '@/utils/commonFunction'

const user = useUserStore()
const useAccount = useAppKitAccount()
</script>

<template>
  <div class="flex-1 p-12 overflow-auto max-sm:p-6">
    <div>
      <div class="flex justify-between items-center pb-4">
        <h2 class="font-nohemi text-3xl font-normal text-blueGray-800">
          Wallet
        </h2>
      </div>

      <div
        class="bg-background-600 w-1/2 rounded-lg shadow-md p-2 space-y-2 text-blueGray-800 font-inter font-medium max-md:w-full"
      >
        <div class="w-full rounded-lg p-4">
          <div class="flex justify-between items-center">
            <h2 class="text-xl font-medium text-black-800">Wallet 1</h2>
          </div>

          <div class="w-full bg-background-700 shadow-md rounded-lg p-4 mt-3">
            <div class="flex flex-col gap-4">
              <div class="flex flex-col justify-between gap-2">
                <span class="text-xs font-semibold text-blueGray-600"
                  >BALANCE</span
                >
                <span class="text-black-800 text-base font-semibold">
                  {{ formatNumber(user.balanceInFiat) }} USD</span
                >
              </div>
              <div class="flex flex-col justify-between gap-2">
                <span class="text-xs font-semibold text-blueGray-600"
                  >Wallet address</span
                >
                <span class="flex gap-2 items-center text-sm text-black-800">
                  <span class="text-black-800 text-base font-semibold">{{
                    truncateString(useAccount.address as string, 5, 5)
                  }}</span>
                  <span>
                    <CopyButton
                      :content="useAccount.address as string"
                      copied="Copied"
                      copy="Click to copy"
                      button-class="stroke-black-800 h-3"
                    />
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
