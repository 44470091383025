<script setup lang="ts">
import { useRouter } from 'vue-router'

const emit = defineEmits(['done'])
const router = useRouter()
const props = defineProps<{
  selectedOption: 'L2Only' | 'L1+L2'
}>()
</script>

<template>
  <div class="flex flex-col gap-4">
    <div class="flex items-center justify-center">
      <div class="flex flex-col gap-1 items-center justify-center">
        <video
          src="@/assets/videos/new_success.webm"
          autoplay
          muted
          class="h-32 relative"
          @contextmenu.prevent="void 0"
        />
      </div>
    </div>
    <div class="flex flex-col gap-4">
      <span
        class="text-2xl font-nohemi font-semibold text-blueGray-800 tabular-nums text-center max-sm:text-xl"
        >All Done!</span
      >
      <span
        v-if="props.selectedOption === 'L1+L2'"
        class="text-base font-inter text-center font-medium text-blueGray-600 tabular-nums max-sm:text-xs"
      >
        You’re all setup to use Chain Abstraction for the smoothest wallet
        experience.</span
      >
      <span
        v-else-if="props.selectedOption === 'L2Only'"
        class="text-base font-inter text-center font-medium text-blueGray-600 tabular-nums max-sm:text-xs"
      >
        You’re all setup to use Chain Abstraction for the smoothest wallet
        experience on Arbitrum, Optimism, Base and Polygon chains.
      </span>
    </div>
    <div class="m-px flex gap-4">
      <button class="button-primary w-1/2 h-11" @click.stop="emit('done')">
        Done
      </button>
      <button
        class="button-secondary w-1/2 h-11"
        @click.stop="router.push({ name: 'Allowances' })"
      >
        Review Allowances
      </button>
    </div>
  </div>
</template>
