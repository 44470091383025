import axios from 'axios'
import { unpack, pack } from 'msgpackr'

const XPSERV_REST_URL = import.meta.env.VITE_XPSERV_REST_URL as string

const getXPUrlWithVersion = function (version: 'v1') {
  return new URL(`${version}`, XPSERV_REST_URL).toString()
}

const axiosXPInstance = axios.create({
  baseURL: getXPUrlWithVersion('v1'),
  responseType: 'arraybuffer',
  transformRequest: [
    function (data, headers) {
      if (['get', 'head'].includes((this.method as string).toLowerCase()))
        return
      headers['Content-Type'] = 'application/msgpack'
      return pack(data)
    },
  ],
  transformResponse: [(data) => unpack(data)],
  headers: {
    Accept: 'application/msgpack',
  },
})

const getXPForAddress = async function (
  universe: 'ETHEREUM',
  address?: `0x${string}`,
) {
  if (!address) return
  const response = await axiosXPInstance.get(
    `/get-stats/${universe}/${address}`,
  )
  const XPResult = response.data
  return {
    universe,
    XPResult,
  }
}

const requestXPForAddress = async function (rffID: string) {
  const response = await axiosXPInstance.post(`/request-tweet-bonus/${rffID}`)
  return response.data
}

export { getXPForAddress, requestXPForAddress }
