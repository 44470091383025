<script lang="ts" setup>
import { computed, onBeforeUnmount, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import BridgeIcon from '@/assets/images/svg/Bridge.svg'
import ConsolidateIcon from '@/assets/images/svg/Consolidate.svg'
import OverviewIcon from '@/assets/images/svg/Overview.svg'
import SendIcon from '@/assets/images/svg/Send.svg'
import { useTokenStore } from '@/stores/token'
import { Avatar, Menu } from '@ark-ui/vue'
import { truncateString } from '@/utils/commonFunction'
import { useAppKitAccount, useDisconnect } from '@reown/appkit/vue'
import ChevronDownIcon from '@/assets/images/svg/ChevronDown.svg'
import CopyButton from '@/components/shared/CopyButton.vue'
import ArrowRightIcon from '@/assets/images/svg/ArrowRight.svg'

const router = useRouter()
const route = useRoute()
const tokenStore = useTokenStore()
const userAccount = useAppKitAccount()
const props = defineProps<{ isSidebarOpen: boolean }>()
const emit = defineEmits(['toggleSidebar'])
const { disconnect } = useDisconnect()
const isOpen = ref(false)
const handleLogout = async () => {
  try {
    if (!userAccount.value?.address) {
      console.warn('No active connection to disconnect.')
      return
    }
    emit('toggleSidebar')
    await disconnect()
    router.push({ name: 'Dashboard' })
  } catch (error) {
    console.log('Disconnect Error', error)
  }
}

const handleRoute = (name: string) => {
  router.push({ name })
  isOpen.value = false
  emit('toggleSidebar')
}

const handleOpenChange = (details: { open: boolean }) => {
  isOpen.value = details.open
}
const handleNavigate = (title: string) => {
  navigateTo(title)
  emit('toggleSidebar')
}

const menuItems = [
  { title: 'Overview', icon: OverviewIcon, link: '/apps' },
  { title: 'Send', icon: SendIcon, link: '/apps/transfer' },
  { title: 'Bridge', icon: BridgeIcon, link: '/apps/bridge' },
  // { title: 'Consolidate', icon: ConsolidateIcon, link: '/apps/consolidate' },
]

const isActiveTab = (link: string) => route.path === link

const navigateTo = (name: string) => {
  router.push({ name })
  tokenStore.clearToken()
}

const isMobileView = ref(window.innerWidth < 768)
const sidebarRef = ref<HTMLElement | null>(null)

const checkScreenSize = () => {
  isMobileView.value = window.innerWidth < 768
}

const handleClickOutside = (event: MouseEvent) => {
  if (
    props.isSidebarOpen &&
    sidebarRef.value &&
    !sidebarRef.value.contains(event.target as Node)
  ) {
    emit('toggleSidebar')
  }
}

onMounted(() => {
  window.addEventListener('resize', checkScreenSize)
  // document.addEventListener('click', handleClickOutside)
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', checkScreenSize)
  // document.removeEventListener('click', handleClickOutside)
})

const toggleSidebar = () => {
  emit('toggleSidebar')
}
</script>

<template>
  <div class="flex overflow-auto">
    <aside
      ref="sidebarRef"
      :class="[
        'bg-background-300 text-gray-700 h-auto min-h-calc-100vh-minus-header transition-transform duration-300 max-md:hidden',
        isMobileView
          ? props.isSidebarOpen
            ? 'fixed top-0 left-0 h-screen z-[999999999] -translate-x-full'
            : 'fixed top-0 left-0 h-screen z-[999999999] -translate-x-full'
          : 'translate-x-0',
      ]"
    >
      <div class="flex justify-between items-center p-4 mb-4 md:hidden">
        <div>
          <img
            src="@/assets/images/svg/ArcanaPayLogo.svg?url"
            alt="Logo"
            class="h-8"
          />
        </div>
        <button
          v-if="isMobileView"
          class="rounded-full h-12 w-12 flex items-center justify-center z-[1000000000]"
          @click="toggleSidebar"
        >
          <span class="text-2xl">{{ props.isSidebarOpen ? '×' : '' }}</span>
        </button>
      </div>

      <div class="flex flex-col mt-6 space-y-4">
        <div
          v-for="item in menuItems"
          :key="item.link"
          class="flex items-center pl-12 pr-8 py-3 cursor-pointer group"
          :class="{
            'text-black-800': isActiveTab(item.link),
            'text-blueGray-400': !isActiveTab(item.link),
            'pl-4': props.isSidebarOpen && isMobileView,
          }"
          @click.stop="handleNavigate(item.title)"
        >
          <component
            :is="item.icon"
            class="mr-4 w-8 stroke-2 group-hover:stroke-black-800 peer"
            :class="{
              'stroke-black-800': isActiveTab(item.link),
              'stroke-blueGray-400': !isActiveTab(item.link),
            }"
          />
          <span
            class="font-nohemi text-base font-medium leading-7 group-hover:text-black-800 peer"
            :class="{
              'text-black-800': isActiveTab(item.link),
              'text-blueGray-400': !isActiveTab(item.link),
            }"
          >
            {{ item.title }}
          </span>
        </div>

        <div class="flex flex-col justify-start gap-6 p-4 mt-auto flex-1">
          <Menu.Root>
            <Menu.Trigger
              class="flex items-center border-none hover:bg-none focus:outline-none active:border-none md:hidden"
            >
              <div class="flex justify-center items-center gap-2">
                <Avatar.Root>
                  <img
                    src="@/assets/images/svg/Wallet.svg?url"
                    alt="avatar"
                    class="h-12 w-12 rounded-6.25rem"
                  />
                </Avatar.Root>
                <div class="flex flex-col items-start justify-between gap-1">
                  <div class="flex items-center justify-between gap-2">
                    <span
                      class="text-maroon-800 font-inter text-base font-semibold uppercase"
                      >Wallet</span
                    >
                  </div>
                  <div class="flex items-center justify-between gap-2">
                    <span class="text-maroon-800 font-inter text-xs">{{
                      truncateString(userAccount.address as string, 5, 5)
                    }}</span>
                    <span>
                      <CopyButton
                        :content="userAccount.address as string"
                        copied="Copied"
                        copy="Click to copy"
                        button-class="stroke-maroon-800 h-3"
                      />
                    </span>
                  </div>
                </div>
              </div>
            </Menu.Trigger>
            <Menu.Positioner>
              <Menu.Content
                class="p-5 rounded-1.25rem border-2 border-white-700 bg-background-300 shadow-0_0_38px_0_00000026 focus:outline-none active:border-none"
              >
                <MenuItem
                  class="flex justify-start items-center p-4 h-2.75rem rounded-0.75rem border border-background-400 bg-background-400 bg-opacity-50 cursor-pointer"
                  @click.stop="handleLogout"
                >
                  <span
                    class="font-nohemi text-0.875rem font-medium text-blueGray-800"
                  >
                    Disconnect Wallet</span
                  >
                </MenuItem>
              </Menu.Content>
            </Menu.Positioner>
          </Menu.Root>

          <Menu.Root :open="isOpen" @open-change="handleOpenChange">
            <Menu.Trigger
              class="flex items-center border-none hover:bg-none focus:outline-none active:border-none md:hidden"
              asChild
            >
              <div
                class="flex items-center justify-between gap-2 rounded-2.93rem px-4 py-2 bg-white-100 border border-background-400"
              >
                <img src="@/assets/images/svg/Setting.svg?url" alt="Setting" />
                <ChevronDownIcon class="h-6 w-6 stroke-maroon-800" />
              </div>
            </Menu.Trigger>
            <Menu.Positioner>
              <Menu.Content
                class="p-5 rounded-1.25rem border-2 border-white-700 bg-background-300 shadow-0_0_38px_0_00000026 focus:outline-none active:border-none w-56"
              >
                <div
                  class="flex flex-col gap-5 justify-start items-start p-4 rounded-0.75rem border border-background-400 bg-background-400 bg-opacity-50 cursor-pointer"
                >
                  <MenuItem
                    class="flex items-center justify-between w-full"
                    @click.stop="handleRoute('Wallet')"
                  >
                    <span
                      class="font-nohemi text-0.875rem font-medium text-blueGray-800"
                    >
                      Wallet</span
                    >
                    <ArrowRightIcon class="stroke-maroon-800" />
                  </MenuItem>
                  <MenuItem
                    class="flex items-center justify-between w-full"
                    @click="handleRoute('Allowances')"
                  >
                    <span
                      class="font-nohemi text-0.875rem font-medium text-blueGray-800"
                    >
                      Allowances</span
                    >
                    <ArrowRightIcon class="stroke-maroon-800" />
                  </MenuItem>
                </div>
              </Menu.Content>
            </Menu.Positioner>
          </Menu.Root>
        </div>
      </div>
    </aside>
  </div>
</template>
