<script lang="ts" setup>
import { useUserStore } from '@/stores/user'
import { formatNumber } from '@/utils/commonFunction'
import { computed } from 'vue'
import ArrowRightIcon from '@/assets/images/svg/TransactionArrow.svg'
import { useRouter } from 'vue-router'

const props = defineProps<{
  title: string
  shwoArrow?: boolean
}>()

const user = useUserStore()
const router = useRouter()

const totalStats = computed(() => {
  return user.XPBalance.reduce(
    (acc, item) => {
      acc.total_xp += item?.XPResult?.total_xp
      acc.tx_count += item?.XPResult?.tx_count
      return acc
    },
    { total_xp: 0, tx_count: 0 },
  )
})
</script>

<template>
  <div
    class="flex justify-between items-center pb-2 max-sm:flex-col-reverse max-sm:gap-2 max-sm:pb-0"
  >
    <ArrowRightIcon
      v-if="
        (props.title !== 'Overview' && props.title !== 'Allowances') ||
        props.shwoArrow
      "
      class="absolute left-6 bottom-0.9rem h-4 w-4 stroke-blueGray-800 rotate-180 sm:hidden"
      @click.stop="router.back()"
    />

    <h2
      class="font-nohemi text-2xl font-normal leading-10 text-blueGray-800 max-sm:bg-white-100 max-sm:w-full max-sm:px-6 max-sm:pt-4 max-sm:text-xl max-sm:font-semibold"
      :class="{
        'max-sm:text-left':
          props.title === 'Overview' || props.title === 'Allowances',
        'max-sm:text-center':
          (props.title !== 'Overview' && props.title !== 'Allowances') ||
          props.shwoArrow,
      }"
    >
      {{ props.title }}
    </h2>

    <div class="flex gap-10 items-center max-sm:gap-4 max-sm:mt-2 max-sm:mb-2">
      <div
        class="flex flex-col items-end gap-2 max-sm:bg-background-600 max-sm:border max-sm:border-background-400 max-sm:p-4 max-sm:flex-row max-sm:rounded-md max-sm:items-center"
      >
        <span
          class="font-inter text-base font-medium text-blueGray-600 max-sm:text-0.688rem"
          >XP Earned</span
        >
        <p
          class="font-inter text-2xl font-bold leading-6 text-blueGray-800 max-sm:text-sm"
        >
          {{ formatNumber(totalStats?.total_xp) }} XP
        </p>
      </div>
      <div
        class="flex flex-col items-end gap-2 max-sm:bg-background-600 max-sm:border max-sm:border-background-400 max-sm:p-4 max-sm:flex-row max-sm:rounded-md max-sm:items-center"
      >
        <span
          class="font-inter text-base font-medium text-blueGray-600 max-sm:text-0.688rem"
          >Total Balance</span
        >
        <p
          class="font-inter text-2xl font-bold leading-6 text-blueGray-800 max-sm:text-sm"
        >
          {{ formatNumber(user.balanceInFiat) }} USD
        </p>
      </div>
    </div>
    <div
      class="hidden w-11/12 h-1 border-t border-t-background-400 max-sm:block"
    />
  </div>
</template>
