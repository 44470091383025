export const symbolToLogo: {
  [key: string]: string
} = {
  ETH: '/images/logos/eth.svg',
  USDC: '/images/logos/usdc.svg',
  MATIC: '/images/logos/polygon.svg',
  POL: '/images/logos/polygon.svg',
  USDT: '/images/logos/usdt.svg',
  WETH: '/images/logos/weth.svg',
  ARB: '/images/logos/arbitrum.svg',
  AVAX: '/images/logos/avax.svg',
  BASE: '/images/logos/base.svg',
  BLAST: '/images/logos/blast.svg',
  CELO: '/images/logos/celo.svg',
  LIN: '/images/logos/linea.svg',
  MANT: '/images/logos/mantle.svg',
  OP: '/images/logos/optimism.svg',
  SCROLL: '/images/logos/scroll.svg',
}
