<script setup lang="ts">
import { RadioGroup } from '@ark-ui/vue'
import { ref } from 'vue'

import AppTooltip from '@/components/shared/AppTooltip.vue'
import { getLogo } from '@/utils/commonFunction'

const emit = defineEmits(['proceed', 'back'])

const selectedOption = ref<string>('L1+L2')
</script>

<template>
  <div class="flex flex-col gap-4">
    <h3
      class="font-nohemi font-semibold text-2xl text-center px-4 text-blueGray-800 max-sm:text-xl"
    >
      Setup the Smoothest Wallet Experience
    </h3>
    <span
      class="text-blueGray-600 text-base text-center font-medium font-inter max-sm:text-xs"
      >Step 1 of 2</span
    >
    <div
      class="field rounded-xl flex flex-col gap-2 p-4 bg-background-600 border border-background-400"
    >
      <RadioGroup.Root
        :model-value="selectedOption"
        class="flex flex-col gap-2"
        @value-change="(details) => (selectedOption = details.value)"
      >
        <RadioGroup.Label
          class="text-blueGray-600 text-base text-start font-normal font-inter max-sm:text-xs"
          >Choose which chains across which you’d like to unify
          balances:</RadioGroup.Label
        >
        <RadioGroup.Indicator />
        <RadioGroup.Item
          key="L1+L2"
          class="flex items-start gap-2 p-4 justify-between bg-background-400 rounded-lg cursor-pointer"
          value="L1+L2"
        >
          <RadioGroup.ItemText class="flex flex-col gap-1">
            <span
              class="font-bold text-base font-inter text-blueGray-700 max-sm:text-xs"
              >Layer 2 + Layer 1 Chains (Recommended)</span
            >
            <span
              class="text-sm font-normal font-inter text-blueGray-600 max-sm:text-xs"
              >Costs 0.001 ETH in gas fees</span
            >
            <div class="flex items-center gap-2 mt-2">
              <AppTooltip message="OP Mainnet">
                <img
                  :src="getLogo('/images/logos/optimism.svg')"
                  class="h-5 w-5 rounded-full"
                />
              </AppTooltip>
              <AppTooltip message="Arbitrum One">
                <img
                  :src="getLogo('/images/logos/arbitrum.svg')"
                  class="h-5 w-5 rounded-full"
                />
              </AppTooltip>
              <AppTooltip message="Base">
                <img
                  :src="getLogo('/images/logos/base.svg')"
                  class="h-5 w-5 rounded-full"
                />
              </AppTooltip>
              <AppTooltip message="Polygon PoS">
                <img
                  :src="getLogo('/images/logos/polygon.svg')"
                  class="h-5 w-5 rounded-full"
                />
              </AppTooltip>
              <span class="font-semibold font-inter text-sm text-black-900"
                >+</span
              >
              <AppTooltip message="Ethereum Mainnet">
                <img
                  :src="getLogo('/images/logos/ethereum.svg')"
                  class="h-5 w-5 rounded-full"
                />
              </AppTooltip>
            </div>
          </RadioGroup.ItemText>
          <RadioGroup.ItemControl
            class="flex-shrink-0 h-5 w-5 rounded-full border-1.5 border border-blueGray-800 text-blueGray-800"
          />
          <RadioGroup.ItemHiddenInput />
        </RadioGroup.Item>
        <RadioGroup.Item
          key="L2Only"
          class="flex items-start gap-2 p-4 justify-between bg-background-400 rounded-lg cursor-pointer"
          value="L2Only"
        >
          <RadioGroup.ItemText class="flex flex-col gap-1">
            <span
              class="font-bold text-base font-inter text-blueGray-700 max-sm:text-xs"
              >Layer 2 Chains Only</span
            >
            <span
              class="text-sm font-normal font-inter text-blueGray-600 max-sm:text-xs"
              >Completely free</span
            >
            <div class="flex items-center gap-2 mt-2">
              <AppTooltip message="OP Mainnet">
                <img
                  :src="getLogo('/images/logos/optimism.svg')"
                  class="h-5 w-5 rounded-full"
                />
              </AppTooltip>
              <AppTooltip message="Arbitrum One">
                <img
                  :src="getLogo('/images/logos/arbitrum.svg')"
                  class="h-5 w-5 rounded-full"
                />
              </AppTooltip>
              <AppTooltip message="Base">
                <img
                  :src="getLogo('/images/logos/base.svg')"
                  class="h-5 w-5 rounded-full"
                />
              </AppTooltip>
              <AppTooltip message="Polygon PoS">
                <img
                  :src="getLogo('/images/logos/polygon.svg')"
                  class="h-5 w-5 rounded-full"
                />
              </AppTooltip>
            </div>
          </RadioGroup.ItemText>
          <RadioGroup.ItemControl
            class="flex-shrink-0 h-5 w-5 rounded-full border-1.5 border border-blueGray-800 text-blueGray-800"
          />
          <RadioGroup.ItemHiddenInput />
        </RadioGroup.Item>
      </RadioGroup.Root>
    </div>
    <div class="flex items-center gap-4">
      <button class="button-primary w-full h-11" @click.stop="emit('back')">
        Back
      </button>
      <button
        class="button-secondary w-full h-11"
        @click.stop="emit('proceed', selectedOption)"
      >
        Proceed
      </button>
    </div>
  </div>
</template>
