<script lang="ts" setup>
import type { CA } from '@arcana/ca-sdk'
import { Avatar, Dialog, Field, NumberInput, Select } from '@ark-ui/vue'
import Decimal from 'decimal.js'
import { maxUint256 } from 'viem'
import { computed, nextTick, onMounted, ref, watch } from 'vue'

import ArrowIcon from '@/assets/images/svg/ArrowUp.svg'
import CheckIcon from '@/assets/images/svg/Check.svg'
import InfoIcon from '@/assets/images/svg/InfoCircle.svg'
import ChevronDownIcon from '@/assets/images/svg/ChevronDown.svg'
import AppLoader from '@/components/shared/AppLoader.vue'
import { useAllowanceStore } from '@/stores/allowance'
import { useUserStore } from '@/stores/user'
import { AllowanceDetailsTypes } from '@/types/allowanceDetailsTypes'
import { Asset, Chain as ChainDetails } from '@/types/balanceTypes'
import { useCaSdkAuth } from '@/use/CaSdkAuth'
import { numberToBigInt } from '@/utils/commonFunction'
import { devLogger } from '@/utils/devLogger'
import AppTooltip from '@/components/shared/AppTooltip.vue'
import AppBalance from './AppBalance.vue'

let caSdkAuth: CA | null = null
const user = useUserStore()
const allowanceStore = useAllowanceStore()
const selectedOptions = ref<{
  token: string[]
  chain: string[]
  amount: string | null
}>({
  token: [''],
  chain: [''],
  amount: null,
})
const feeData = ref<{
  maxFeePerGas: string
  gasPrice: string
  maxPriorityFeePerGas: string
}>({
  maxFeePerGas: '0',
  gasPrice: '0',
  maxPriorityFeePerGas: '0',
})
const allLoader = ref<{
  inProgress: boolean
  completed: boolean
  error: boolean
}>({
  inProgress: false,
  completed: false,
  error: false,
})

const getTokenAndChainDetails = (assets: Asset[]) => {
  const tokenSet = new Set<string>()
  const chainMap = new Map<number, ChainDetails>()

  assets.forEach((asset) => {
    tokenSet.add(asset.symbol)

    asset.breakdown.forEach((breakdown) => {
      const chainWithSymbol = {
        ...breakdown.chain,
        abstracted: asset.abstracted,
      }

      if (!chainMap.has(breakdown.chain.id)) {
        chainMap.set(Number(breakdown.chain.id), chainWithSymbol)
      }
    })
  })

  return {
    token: Array.from(tokenSet),
    chain: Array.from(chainMap.values()),
  }
}

const chainList = computed(() => getTokenAndChainDetails(user.assets).chain)

const selectedChain = computed(() => {
  return chainList.value.find(
    (c) => c.id.toString() === selectedOptions.value.chain[0],
  )
})

const selectedToken = computed(() => {
  return availableTokens.value.find((token) => {
    return token.breakdown.find(
      (b) => b.contractAddress.toString() === selectedOptions.value.token[0],
    )
  })
})

const availableTokens = computed(() => {
  const userAssets = user.assets
  return userAssets
    .filter(
      (asset) =>
        asset.symbol !== 'ETH' &&
        asset.breakdown.find(
          (b) => b.chain.id.toString() === selectedOptions.value.chain[0],
        ),
    )
    .map((asset) => {
      return {
        ...asset,
        value: asset.breakdown.find(
          (b) => b.chain.id.toString() === selectedOptions.value.chain[0],
        )?.contractAddress,
      }
    })
})

const handleMax = async () => {
  if (selectedToken.value) {
    selectedOptions.value.amount = 'Unlimited'
    await nextTick()
  }
}

const getSymbolByContractAddress = (
  assets: Asset[],
  contractAddress: string,
): string => {
  const matchedItem = assets?.filter((item) =>
    item.breakdown.find(
      (item) =>
        item.contractAddress.toLowerCase() === contractAddress.toLowerCase(),
    ),
  )

  return matchedItem[0].symbol
}

const handleAmountInput = (event: Event) => {
  const input = (event.target as HTMLInputElement).value

  if (!input || isNaN(Number(input))) {
    selectedOptions.value.amount = ''
    return
  }

  const amount = new Decimal(input)
  const maxAllowed = new Decimal(maxUint256.toString())

  selectedOptions.value.amount = amount.greaterThan(maxAllowed)
    ? 'Unlimited'
    : input
}

const clearData = () => {
  selectedOptions.value.token = ['']
  selectedOptions.value.chain = ['']
  selectedOptions.value.amount = null
}

const revokeAllowance = async () => {
  allLoader.value.inProgress = true
  allLoader.value.error = false
  try {
    if (caSdkAuth) {
      const token = getSymbolByContractAddress(
        availableTokens.value,
        selectedOptions.value.token[0],
      )

      await caSdkAuth
        .allowance()
        .tokens([token])
        .chain(Number(selectedOptions.value.chain[0]))
        .revoke()

      allLoader.value.completed = true
      clearData()
    }
  } catch (error) {
    devLogger.log('Revoke Failed:', error)
    allLoader.value.inProgress = false
    allLoader.value.error = true
  } finally {
    devLogger.log('Revoke')
  }
}

const setAllowance = async () => {
  allLoader.value.inProgress = true
  allLoader.value.error = false
  try {
    if (caSdkAuth) {
      const token = getSymbolByContractAddress(
        availableTokens.value,
        selectedOptions.value.token[0],
      )

      const amount =
        selectedOptions.value.amount === 'Unlimited'
          ? maxUint256
          : numberToBigInt(Number(selectedOptions.value.amount))

      await caSdkAuth
        .allowance()
        .tokens([token])
        .chain(Number(selectedOptions.value.chain[0]))
        .amount(amount)
        .set()

      allLoader.value.completed = true
    }
  } catch (error) {
    devLogger.log('Set Allowance Failed:', error)
    allLoader.value.inProgress = false
    allLoader.value.error = true
  } finally {
    devLogger.log('Set Allowance')
  }
}

const closeModal = () => {
  allLoader.value.inProgress = false
  allLoader.value.error = false
  allLoader.value.completed = false
}

watch(
  () => allowanceStore.selectedAllowanceData,
  (newToken: AllowanceDetailsTypes | null) => {
    if (newToken) {
      const data = user.assets
        .filter((asset) =>
          asset.breakdown.find(
            (b) => b.chain.id.toString() === newToken.chainID.toString(),
          ),
        )
        .filter((item) => item.symbol === newToken.token)

      selectedOptions.value.chain = [newToken.chainID.toString()]
      selectedOptions.value.token = [
        data[0].breakdown[0].contractAddress.toString(),
      ]
    }
  },
  { immediate: true },
)

onMounted(async () => {
  try {
    caSdkAuth = await useCaSdkAuth()
  } catch (error) {
    console.error('Error initializing CA SDK Auth:', error)
  }
})
</script>

<template>
  <div class="flex-1 p-12 overflow-auto max-sm:p-6">
    <div class="">
      <div class="hidden justify-between items-center pb-2 sm:flex">
        <h2 class="font-nohemi text-2xl font-normal leading-10 text-gray-800">
          Allowances
        </h2>
      </div>

      <div
        class="max-sm:relative max-sm:-m-6 max-sm:bg-background-500 max-sm:mb-4 sm:hidden"
      >
        <AppBalance title="Allowances" :shwoArrow="true" />
      </div>

      <div
        class="bg-background-500 w-1/2 rounded-lg shadow-md p-6 space-y-4 text-blueGray-800 font-inter font-normal text-sm max-md:w-full"
      >
        <Field.Root>
          <Field.Label class="font-inter text-sm font-normal text-blueGray-800"
            >Contract Name</Field.Label
          >
          <Field.Input
            class="w-full shadow-sm border border-background-400 disabled:bg-white-100 placeholder:text-blueGray-600"
            placeholder="Arcana Vault Contract V1"
            :disabled="true"
          />
        </Field.Root>

        <Field.Root>
          <Select.Root
            v-model="selectedOptions.chain"
            class="w-full flex flex-col gap-1 relative z-40 isolate"
            :items="chainList"
          >
            <Select.Label
              class="font-inter text-sm font-normal text-blueGray-800 placeholder:text-blueGray-600"
              >Chain</Select.Label
            >
            <Select.Control class="outline-none field">
              <Select.Trigger
                class="flex rounded-md items-center w-full font-inter text-base font-medium text-blueGray-800 shadow-sm bg-white-100 text-start h-10 px-4 py-2 border border-background-400 placeholder:text-blueGray-600"
              >
                <div
                  class="flex-grow flex items-center gap-2 font-medium text-base"
                >
                  <Avatar.Root>
                    <Avatar.Image
                      :src="selectedChain?.logo"
                      class="w-5 h-5 rounded-full"
                    />
                  </Avatar.Root>
                  <span>{{ selectedChain?.name || 'Chain' }}</span>
                </div>
                <Select.Indicator>
                  <ChevronDownIcon class="w-4 h-4 stroke-blueGray-800" />
                </Select.Indicator>
              </Select.Trigger>
            </Select.Control>
            <Select.Positioner class="w-full z-50">
              <Select.Content
                class="max-h-90 w-full rounded-lg text-sm bg-white-100"
              >
                <Select.ItemGroup>
                  <Select.Item
                    v-for="chain in chainList"
                    :key="chain.id"
                    :item="chain.id.toString()"
                    class="px-4 py-3 w-full flex rounded-md justify-between hover:bg-blueGray-300"
                  >
                    <div class="flex items-center gap-2">
                      <Avatar.Root>
                        <Avatar.Fallback class="w-5 h-5 rounded-full">{{
                          chain.name.split(' ')[0].substring(0, 2).toUpperCase()
                        }}</Avatar.Fallback>
                        <Avatar.Image
                          :src="chain.logo"
                          class="w-5 h-5 rounded-full"
                        />
                      </Avatar.Root>
                      <span>{{ chain.name }}</span>

                      <Select.ItemText class="hidden">{{
                        chain.id
                      }}</Select.ItemText>
                    </div>
                    <Select.ItemIndicator
                      ><CheckIcon class="w-5 h-5 stroke-black-700"
                    /></Select.ItemIndicator>
                  </Select.Item>
                </Select.ItemGroup>
              </Select.Content>
            </Select.Positioner>
            <Select.HiddenSelect />
          </Select.Root>
        </Field.Root>

        <Field.Root>
          <Select.Root
            v-model="selectedOptions.token"
            class="w-full flex flex-col gap-1 relative z-30 isolate"
            :items="availableTokens"
          >
            <Select.Label
              class="font-inter text-sm font-normal text-blueGray-800 placeholder:text-blueGray-600"
              >Token</Select.Label
            >
            <Select.Control class="outline-none field">
              <Select.Trigger
                class="flex rounded-md items-center w-full font-inter text-base font-medium text-blueGray-800 shadow-sm bg-white-100 text-start h-10 px-4 py-2 border border-background-400 placeholder:text-blueGray-600"
                :disabled="!selectedOptions.chain[0]"
              >
                <div
                  class="flex-grow flex items-center gap-2 font-medium text-base"
                >
                  <Avatar.Root>
                    <Avatar.Image
                      :src="selectedToken?.icon"
                      class="w-5 h-5 rounded-full"
                    />
                  </Avatar.Root>
                  <span>{{ selectedToken?.symbol || 'Token' }}</span>
                  <div
                    v-if="selectedToken?.abstracted"
                    class="text-rose-500 text-0.625rem font-inter font-normal flex items-center gap-1 p-1 rounded-full bg-rose-200"
                  >
                    Chain Abstracted
                    <AppTooltip message="Chain Abstracted">
                      <InfoIcon
                        class="h-3 w-3 stroke-rose-500 stroke-cap-round"
                      />
                    </AppTooltip>
                  </div>
                </div>
                <Select.Indicator>
                  <ChevronDownIcon class="w-4 h-4 stroke-blueGray-800" />
                </Select.Indicator>
              </Select.Trigger>
            </Select.Control>
            <Select.Positioner class="w-full z-50">
              <Select.Content
                class="max-h-60 w-full rounded-lg text-sm bg-white-100"
              >
                <Select.ItemGroup>
                  <Select.Item
                    v-for="token in availableTokens"
                    :key="token.breakdown[0].contractAddress"
                    :item="token.breakdown[0].contractAddress"
                    class="px-4 py-3 w-full flex rounded-md justify-between hover:bg-blueGray-300"
                  >
                    <div class="flex items-center gap-2">
                      <Avatar.Root>
                        <Avatar.Fallback class="w-5 h-5 rounded-full">{{
                          token.symbol
                            .split(' ')[0]
                            .substring(0, 2)
                            .toUpperCase()
                        }}</Avatar.Fallback>
                        <Avatar.Image
                          :src="token.icon"
                          class="w-5 h-5 rounded-full"
                        />
                      </Avatar.Root>
                      <span>{{ token.symbol }}</span>
                      <div
                        v-if="token?.abstracted"
                        class="text-rose-500 text-0.625rem font-inter font-normal flex items-center gap-1 p-1 rounded-full bg-rose-200"
                      >
                        Chain Abstracted
                        <AppTooltip message="Chain Abstracted">
                          <InfoIcon
                            class="h-3 w-3 stroke-rose-500 stroke-cap-round"
                          />
                        </AppTooltip>
                      </div>
                    </div>
                    <Select.ItemIndicator
                      ><CheckIcon class="w-5 h-5 stroke-black-700"
                    /></Select.ItemIndicator>
                  </Select.Item>
                </Select.ItemGroup>
              </Select.Content>
            </Select.Positioner>
            <Select.HiddenSelect />
          </Select.Root>
        </Field.Root>

        <Field.Root>
          <NumberInput.Root>
            <NumberInput.Label
              class="font-inter text-sm font-normal text-blueGray-800"
              >Allowance Limit</NumberInput.Label
            >

            <div class="relative flex items-center">
              <NumberInput.Input
                :value="selectedOptions.amount"
                class="w-full shadow-sm border border-background-400 placeholder:text-blueGray-600"
                placeholder="0"
                :min="0"
                @input="handleAmountInput"
                @focus="
                  () => {
                    const amount = selectedOptions.amount
                      ? new Decimal(selectedOptions.amount)
                      : null
                    const maxAllowed = new Decimal(maxUint256.toString())

                    if (amount && amount.greaterThan(maxAllowed)) {
                      selectedOptions.amount = 'Unlimited'

                      if (new Decimal(selectedOptions.amount).lessThan(0)) {
                        selectedOptions.amount = '0'
                      }
                    }
                  }
                "
              />

              <span
                class="absolute flex items-center gap-2 right-2 px-4 py-1 text-sm font-semibold font-inter uppercase border-l-2 border-blueGray-200 cursor-pointer text-blueGray-700"
                :disabled="!selectedToken"
                @click.stop="handleMax"
              >
                <ArrowIcon class="stroke-blueGray-700" />
                Max
              </span>
            </div>
          </NumberInput.Root>
        </Field.Root>
      </div>

      <div
        class="w-1/2 flex items-center justify-start gap-4 rounded-full my-5 max-md:w-full max-sm:flex-col"
      >
        <button
          class="button-primary w-6/12 h-12 max-sm:w-full"
          @click.stop="revokeAllowance"
        >
          Revoke Allowance
        </button>
        <button
          class="button-primary w-6/12 h-12 max-sm:w-full"
          @click.stop="setAllowance"
        >
          Save
        </button>
      </div>

      <Dialog.Root v-model:open="allLoader.inProgress">
        <Teleport to="body">
          <Dialog.Backdrop />
          <Dialog.Positioner
            class="fixed inset-0 flex items-center justify-center z-50"
            :class="{ 'bg-orange-400': allLoader.inProgress }"
          >
            <Dialog.Content class="card">
              <AppLoader
                :loader="allLoader"
                :chain="selectedChain?.name"
                @closeModal="closeModal"
              />
            </Dialog.Content>
          </Dialog.Positioner>
        </Teleport>
      </Dialog.Root>
    </div>
  </div>
</template>
