<script lang="ts" setup>
import { Avatar, Menu, Dialog } from '@ark-ui/vue'
import {
  useAppKitAccount,
  useAppKitEvents,
  useDisconnect,
  useWalletInfo,
} from '@reown/appkit/vue'
import { computed, onMounted, ref, watch, onUnmounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import ArrowRightIcon from '@/assets/images/svg/ArrowRight.svg'
import ChevronDownIcon from '@/assets/images/svg/ChevronDown.svg'
import CopyButton from '@/components/shared/CopyButton.vue'
import BridgeIcon from '@/assets/images/svg/Bridgem.svg'
import OverviewIcon from '@/assets/images/svg/Overviewm.svg'
import SendIcon from '@/assets/images/svg/Sendm.svg'
import HamBar from '@/assets/images/svg/Ham.svg'
import Close from '@/assets/images/svg/ClosePink.svg'
import { useWalletConnect } from '@/composables/walletConnect'
import { useAllowanceStore } from '@/stores/allowance'
import { useUserStore } from '@/stores/user'
import { useCaSdkAuth } from '@/use/CaSdkAuth'
import { truncateString } from '@/utils/commonFunction'
import { devLogger } from '@/utils/devLogger'
import { trackEvent } from '@/segment/segment'
import { CA } from '@arcana/ca-sdk'
import { useTokenStore } from '@/stores/token'

const props = defineProps<{ isSidebarOpen: boolean }>()
const emit = defineEmits(['toggleSidebar'])
const sidebarRef = ref<HTMLElement | null>(null)
const tokenStore = useTokenStore()
const hamburgerRef = ref<HTMLElement | null>(null)
const user = useUserStore()
const router = useRouter()
const route = useRoute()
const walletConnect = useWalletConnect()
const allowanceStore = useAllowanceStore()
const userAccount = useAppKitAccount()
const { walletInfo } = useWalletInfo()
const { disconnect } = useDisconnect()
const events = useAppKitEvents()
const isOpen = ref(false)
const intervalId = ref<ReturnType<typeof setInterval> | null>(null)

const isActiveTab = (link: string) => route.path === link

const handleLogout = async () => {
  try {
    if (!userAccount.value?.address) {
      console.warn('No active connection to disconnect.')
      return
    }

    await disconnect()
    emit('toggleSidebar')
    router.push({ name: 'Dashboard' })
  } catch (error) {
    console.log('Disconnect Error', error)
  }
}

const handleLogin = async () => {
  try {
    await walletConnect.web3Modal.value.open()
  } catch (error) {
    devLogger.error('Connect Error', error)
  }
}

const loadAssets = async (path: string) => {
  if (path === '/') return
  const caSdkAuth = await useCaSdkAuth()
  if (caSdkAuth) {
    const balances = await caSdkAuth.getUnifiedBalances()
    const displayableAssets =
      balances &&
      balances.sort((a, b) => {
        if (a.abstracted && !b.abstracted) {
          return -1
        }
        if (!a.abstracted && b.abstracted) {
          return 1
        }
        return 0
      })
    user.setAsset(displayableAssets)
    if (userAccount?.value?.address) {
      user.fetchXPBalance(userAccount?.value?.address as `0x${string}`)
    }
    // setBalancePolling(caSdkAuth)
  }
}

const loadAllowance = async (path: string) => {
  if (path === '/') return
  const caSdkAuth = await useCaSdkAuth()
  if (caSdkAuth) {
    const allAllowance = await caSdkAuth.allowance().get()
    allowanceStore.setUserAllowance(allAllowance)
  }
}

const handleRoute = (name: string) => {
  router.push({ name })
  isOpen.value = false
  emit('toggleSidebar')
}

const totalFiatBalance = computed(() => {
  if (user.assets.length) {
    return user.assets.reduce(
      (total, asset) => total + (asset.balanceInFiat || 0),
      0,
    )
  }
  return 0
})

const setBalancePolling = (caSdkAuth: CA) => {
  if (caSdkAuth) {
    intervalId.value = setInterval(async () => {
      const balances = await caSdkAuth.getUnifiedBalances()
      const displayableAssets =
        balances &&
        balances.sort((a, b) => {
          if (a.abstracted && !b.abstracted) {
            return -1
          }
          if (!a.abstracted && b.abstracted) {
            return 1
          }
          return 0
        })
      user.setAsset(displayableAssets)
      if (userAccount?.value?.address) {
        user.fetchXPBalance(userAccount?.value?.address as `0x${string}`)
      }
    }, 2000)
  }
}

const menuItems = [
  { title: 'Overview', icon: OverviewIcon, link: '/apps' },
  { title: 'Send', icon: SendIcon, link: '/apps/transfer' },
  { title: 'Bridge', icon: BridgeIcon, link: '/apps/bridge' },
]

const handleNavigate = (title: string) => {
  navigateTo(title)
}

const navigateTo = (name: string) => {
  router.push({ name })
  tokenStore.clearToken()
}

watch(totalFiatBalance, (newTotalFiatBalance) => {
  user.balanceInFiat = newTotalFiatBalance || 0
})

watch(
  () => route.path,
  (newPath) => {
    loadAssets(newPath)
    loadAllowance(newPath)
  },
)

watch(
  () => userAccount.value.isConnected,
  (newValue) => {
    if (newValue) {
      router.push({ name: 'Overview' })
    } else {
      router.push({ name: 'Dashboard' })
    }
  },
)

watch(
  () => events.data,
  async (newValue) => {
    if (newValue.event === 'CONNECT_SUCCESS') {
      router.push({ name: 'Overview' })
      trackEvent('Wallet Login', {
        appName: 'Arcana Pay App',
        buttonName: 'Wallet Login',
        environment: import.meta.env.VITE_ENVIOURMENT,
        walletAddress: userAccount.value.address,
        walletName: walletInfo.value?.name,
        timestamp: new Date().toISOString(),
      })
    }
  },
  { deep: true },
)

const handleOpenChange = (details: { open: boolean }) => {
  isOpen.value = details.open
}

onMounted(async () => {
  await loadAssets(route.path)
  await loadAllowance(route.path)
})

const handleClickOutside = (event: MouseEvent) => {
  if (
    sidebarRef.value &&
    !sidebarRef.value.contains(event.target as Node) &&
    hamburgerRef.value &&
    !hamburgerRef.value.contains(event.target as Node)
  ) {
    emit('toggleSidebar')
  }
}

onMounted(() => {
  document.addEventListener('click', handleClickOutside)
})

onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside)
})

// onUnmounted(async () => {
//   if (intervalId.value) {
//     clearInterval(intervalId.value)
//     intervalId.value = null
//     console.log('Interval cleared.')
//   }
// })
</script>

<template>
  <div class="h-5.125rem max-sm:h-16">
    <header
      :class="[
        'flex py-1.0625rem px-12 justify-between items-center max-md:gap-4 max-md:px-5',
        !userAccount.isConnected
          ? 'relative z-50 bg-white-1200 shadow-0_4_21_6_0_0000001A max-sm:justify-center max-sm:shadow-none'
          : 'bg-background-300',
      ]"
    >
      <div>
        <img
          src="@/assets/images/svg/ArcanaPayLogo.svg?url"
          alt="Logo"
          class="h-3rem"
          :class="{
            'max-sm:h-9': userAccount.isConnected,
            'max-md:h-15': !userAccount.isConnected,
          }"
        />
      </div>

      <div>
        <div v-if="!userAccount.isConnected" class="block max-sm:hidden">
          <button
            class="button-secondary w-11.625rem h-3.25rem"
            @click.stop="handleLogin"
          >
            Connect Wallet
          </button>
        </div>

        <div v-else class="flex items-center justify-end gap-10">
          <Menu.Root v-if="userAccount.address">
            <Menu.Trigger
              class="flex items-center border-none hover:bg-none focus:outline-none active:border-none max-md:hidden"
            >
              <div class="flex justify-center items-center gap-2">
                <Avatar.Root>
                  <img
                    src="@/assets/images/svg/Wallet.svg?url"
                    alt="avatar"
                    class="h-12 w-12 rounded-6.25rem"
                  />
                </Avatar.Root>
                <div class="flex flex-col items-start justify-between gap-1">
                  <div class="flex items-center justify-between gap-2">
                    <span
                      class="text-maroon-800 font-inter text-base font-semibold uppercase"
                      >Wallet</span
                    >
                  </div>
                  <div class="flex items-center justify-between gap-2">
                    <span class="text-maroon-800 font-inter text-xs">{{
                      truncateString(userAccount.address as string, 5, 5)
                    }}</span>
                    <span>
                      <CopyButton
                        :content="userAccount.address as string"
                        copied="Copied"
                        copy="Click to copy"
                        button-class="stroke-maroon-800 h-3"
                      />
                    </span>
                  </div>
                </div>
              </div>
            </Menu.Trigger>
            <Menu.Positioner>
              <Menu.Content
                class="p-5 rounded-1.25rem border-2 border-white-700 bg-background-300 shadow-0_0_38px_0_00000026 focus:outline-none active:border-none"
              >
                <MenuItem
                  class="flex justify-start items-center p-4 h-2.75rem rounded-0.75rem border border-background-400 bg-background-400 bg-opacity-50 cursor-pointer"
                  @click.stop="handleLogout"
                >
                  <span
                    class="font-nohemi text-0.875rem font-medium text-blueGray-800"
                  >
                    Disconnect Wallet</span
                  >
                </MenuItem>
              </Menu.Content>
            </Menu.Positioner>
          </Menu.Root>

          <Menu.Root :open="isOpen" @open-change="handleOpenChange">
            <Menu.Trigger
              class="flex items-center border-none hover:bg-none focus:outline-none active:border-none max-md:hidden"
              asChild
            >
              <div
                class="flex items-center justify-between gap-2 rounded-2.93rem px-4 py-2 bg-white-100 border border-background-400"
              >
                <img src="@/assets/images/svg/Setting.svg?url" alt="Setting" />
                <ChevronDownIcon class="h-6 w-6 stroke-maroon-800" />
              </div>
            </Menu.Trigger>
            <Menu.Positioner>
              <Menu.Content
                class="p-5 rounded-1.25rem border-2 border-white-700 bg-background-300 shadow-0_0_38px_0_00000026 focus:outline-none active:border-none w-56"
              >
                <div
                  class="flex flex-col gap-5 justify-start items-start p-4 rounded-0.75rem border border-background-400 bg-background-400 bg-opacity-50 cursor-pointer"
                >
                  <MenuItem
                    class="flex items-center justify-between w-full"
                    @click.stop="handleRoute('Wallet')"
                  >
                    <span
                      class="font-nohemi text-0.875rem font-medium text-blueGray-800"
                    >
                      Wallet</span
                    >
                    <ArrowRightIcon class="stroke-maroon-800" />
                  </MenuItem>
                  <MenuItem
                    class="flex items-center justify-between w-full"
                    @click="handleRoute('Allowances')"
                  >
                    <span
                      class="font-nohemi text-0.875rem font-medium text-blueGray-800"
                    >
                      Allowances</span
                    >
                    <ArrowRightIcon class="stroke-maroon-800" />
                  </MenuItem>
                </div>
              </Menu.Content>
            </Menu.Positioner>
          </Menu.Root>

          <div class="flex justify-between items-center gap-2 md:hidden">
            <div
              v-for="item in menuItems"
              :key="item.link"
              class="flex items-center cursor-pointer group"
              :class="{
                'text-rose-500': isActiveTab(item.link),
                'text-black-800': !isActiveTab(item.link),
              }"
              @click.stop="handleNavigate(item.title)"
            >
              <component
                :is="item.icon"
                class="w-8 stroke-2 group-hover:stroke-rose-500 peer"
                :class="{
                  'stroke-rose-500': isActiveTab(item.link),
                  'stroke-black-800': !isActiveTab(item.link),
                }"
              />
            </div>
            <button
              ref="hamburgerRef"
              class="text-3xl"
              @click.stop="emit('toggleSidebar')"
            >
              <HamBar v-if="!props.isSidebarOpen" class="w-8" />
              <Close v-else class="w-8" />
            </button>
          </div>

          <transition
            enter-active-class="transition-opacity duration-200 ease-out"
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition-opacity duration-150 ease-in"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <div
              v-if="props.isSidebarOpen"
              ref="sidebarRef"
              class="absolute top-16 right-0 w-full bg-white-100 shadow-lg rounded-b-lg border border-background-400 z-999999999 p-6 md:hidden"
            >
              <div class="bg-background-600 shadow-lg rounded-lg">
                <Menu.Root>
                  <Menu.Trigger
                    class="flex items-center border-none hover:bg-none focus:outline-none active:border-none"
                  >
                    <div class="p-4 flex items-center gap-3">
                      <img
                        src="@/assets/images/svg/Wallet.svg?url"
                        alt="avatar"
                        class="h-12 w-12 rounded-6.25rem"
                      />
                      <div
                        class="flex flex-col items-start justify-between gap-1"
                      >
                        <div class="flex items-center justify-between gap-2">
                          <span
                            class="text-maroon-800 font-inter text-base font-semibold uppercase"
                            >Wallet</span
                          >
                        </div>
                        <div class="flex items-center justify-between gap-2">
                          <span class="text-maroon-800 font-inter text-xs">{{
                            truncateString(userAccount.address as string, 5, 5)
                          }}</span>
                          <span>
                            <CopyButton
                              :content="userAccount.address as string"
                              copied="Copied"
                              copy="Click to copy"
                              button-class="stroke-maroon-800 h-3"
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Menu.Trigger>
                  <Menu.Positioner>
                    <Menu.Content
                      class="p-5 rounded-1.25rem border-2 border-white-700 bg-background-300 shadow-0_0_38px_0_00000026 focus:outline-none active:border-none"
                    >
                      <MenuItem
                        class="flex justify-start items-center p-4 h-2.75rem rounded-0.75rem border border-background-400 bg-background-400 bg-opacity-50 cursor-pointer"
                        @click.stop="handleLogout"
                      >
                        <span
                          class="font-nohemi text-0.875rem font-medium text-blueGray-800"
                        >
                          Disconnect Wallet</span
                        >
                      </MenuItem>
                    </Menu.Content>
                  </Menu.Positioner>
                </Menu.Root>

                <div class="p-2 flex flex-col">
                  <button
                    class="w-full text-left px-4 py-3 flex items-center justify-between"
                    @click.stop="handleRoute('Wallet')"
                  >
                    <span
                      class="font-nohemi text-base font-medium text-blueGray-800"
                    >
                      Wallet</span
                    >
                    <ArrowRightIcon class="stroke-maroon-800" />
                  </button>
                  <button
                    class="w-full text-left px-4 py-3 flex items-center justify-between"
                    @click="handleRoute('Allowances')"
                  >
                    <span
                      class="font-nohemi text-base font-medium text-blueGray-800"
                    >
                      Allowances</span
                    >
                    <ArrowRightIcon class="stroke-maroon-800" />
                  </button>
                </div>
              </div>
            </div>
          </transition>

          <transition
            enter-active-class="transition-opacity duration-200 ease-out"
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition-opacity duration-150 ease-in"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <div
              v-if="props.isSidebarOpen"
              class="absolute w-full h-screen-minus-20 right-0 bg-orange-400 top-20 z-50 md:hidden"
            ></div>
          </transition>
        </div>
      </div>
    </header>
  </div>
</template>
