<script lang="ts" setup>
import { useWalletConnect } from '@/composables/walletConnect'
import { devLogger } from '@/utils/devLogger'
import { useAppKitAccount } from '@reown/appkit/vue'
import { computed, onBeforeUnmount, onMounted, ref } from 'vue'

const useAccount = useAppKitAccount()
const walletConnect = useWalletConnect()

const openCalendly = () => {
  if (typeof window !== 'undefined') {
    window.open(
      'https://calendly.com/arcana-network/arcana-demo-walkthrough',
      '_blank',
    )
  }
}

const openKnowMore = () => {
  if (typeof window !== 'undefined') {
    window.open(
      'https://blog.arcana.network/arcana-chain-abstraction-launches-xp-program',
      '_blank',
    )
  }
}

const walletImages = [
  new URL('@/assets/images/dashboard/arcana_wallet.png', import.meta.url).href,
  new URL('@/assets/images/dashboard/metamask.png', import.meta.url).href,
  new URL('@/assets/images/dashboard/rabby_wallet.png', import.meta.url).href,
  new URL('@/assets/images/dashboard/keplr.png', import.meta.url).href,
  new URL('@/assets/images/dashboard/phantom.png', import.meta.url).href,
  new URL('@/assets/images/dashboard/okx.png', import.meta.url).href,
  new URL('@/assets/images/dashboard/zerion.png', import.meta.url).href,
  new URL('@/assets/images/dashboard/coinbase.png', import.meta.url).href,
  new URL('@/assets/images/dashboard/rainbow_wallet.png', import.meta.url).href,
]

const duplicatedImages = computed(() => [...walletImages, ...walletImages])
const isMobileView = ref(window.innerWidth < 680)

const handleLogin = async () => {
  try {
    await walletConnect.web3Modal.value.open()
  } catch (error) {
    devLogger.error('Connect Error', error)
  }
}

const checkScreenSize = () => {
  isMobileView.value = window.innerWidth < 768
}

onMounted(() => {
  window.addEventListener('resize', checkScreenSize)
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', checkScreenSize)
})
</script>

<template>
  <div
    class="flex flex-col bg-white-1200"
    :class="{
      'bg-white-100': useAccount.isConnected,
    }"
    v-if="!useAccount.isConnected"
  >
    <section class="text-center pt-16 px-6 max-lg:px-2 max-sm:px-4">
      <h1
        class="font-nohemi text-3.25rem font-normal text-blueGray-800 max-sm:text-black-1100 max-lg:text-2.125rem"
      >
        <span
          v-html="
            isMobileView
              ? 'Cross-chain transactions with Unified Balance'
              : 'Cross-chain transactions <br> with Unified Balance'
          "
        ></span>
      </h1>
      <p
        class="font-inter mt-6 text-xl font-normal text-black-800 max-sm:text-black-1100 max-sm:text-base"
      >
        Send or Bridge your aggregated balances across multiple EVM chains in a
        single click.
      </p>

      <div class="hidden mt-6 max-sm:flex max-sm:justify-center max-sm:mt-10">
        <button
          class="bg-blueGray-800 border border-solid border-blueGray-800 text-background-300 transition-all enabled:hover:bg-blueGray-800 enabled:focus-visible:bg-blueGray-800 rounded-full h-12 disabled:opacity-30 font-inter text-sm font-semibold w-11.625rem max-sm:text-xs max-sm:font-medium max-sm:h-11 max-sm:w-32"
          @click.stop="handleLogin"
        >
          Connect Wallet
        </button>
      </div>
    </section>

    <section class="flex justify-center pt-16 px-20 max-lg:px-8 max-sm:px-4">
      <video
        src="@/assets/images/dashboard/arcana_pay_hero_animation.mp4"
        width="100%"
        height="100%"
        class="rounded-xl"
        autoplay
        loop
        muted
      />
    </section>

    <section class="text-center pt-16 max-sm:px-4">
      <h2
        class="font-nohemi text-3.25rem leading-relaxed font-normal text-blueGray-800 max-lg:text-4xl max-sm:text-1.75rem max-sm:font-normal"
      >
        Supports your favorite wallet
      </h2>
      <div class="mt-14 mx-40 max-lg:mx-10 max-sm:mx-4">
        <div class="overflow-hidden w-full relative flex">
          <div class="flex w-max items-center animate-slide">
            <div
              v-for="(image, index) in duplicatedImages"
              :key="index"
              class="flex w-72 mx-2 max-lg:w-44 max-lg:justify-center max-sm:w-48"
            >
              <img
                :src="image"
                alt="Wallet"
                class="w-3/5 rounded-lg max-sm:w-full"
              />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section
      class="flex justify-between items-center pt-24 pb-20 px-24 max-lg:px-8 max-sm:flex-col max-sm:px-4"
    >
      <div class="flex flex-col gap-6 max-sm:items-center">
        <h2
          class="font-nohemi text-3.25rem leading-relaxed font-normal text-blueGray-800 max-lg:text-4xl max-sm:text-center max-sm:text-1.75rem max-sm:px-20"
        >
          Earn XP and win rewards!
        </h2>
        <button
          class="bg-blueGray-800 border border-solid border-blueGray-800 text-background-300 transition-all enabled:hover:bg-blueGray-800 enabled:focus-visible:bg-blueGray-800 rounded-full h-12 disabled:opacity-30 font-inter text-sm font-semibold w-11.625rem leading-normal max-sm:font-medium"
          @click.stop="openKnowMore"
        >
          Know More
        </button>
      </div>
      <img
        src="@/assets/images/dashboard/dollar.png"
        alt="Dollar"
        class="w-7/12 max-xl:w-6/12 max-md:w-8/12 max-sm:w-full max-sm:mt-8"
      />
    </section>

    <footer
      class="relative z-10 bg-blueGray-800 px-24 p-10 text-white rounded-tl-3.75rem rounded-tr-3.75rem max-lg:px-8 max-sm:px-10 max-sm:rounded-tl-1.875rem max-sm:rounded-tr-1.875rem"
    >
      <div class="container mx-auto flex flex-col gap-6">
        <div class="leading-none">
          <div class="flex items-center gap-2">
            <img
              src="@/assets/images/svg/ArcanaPayLogo.svg?url"
              alt="Arcana Pay Logo"
              class="h-3rem"
            />
          </div>
        </div>
        <div class="flex flex-col gap-8 z-50">
          <div
            class="flex items-end gap-24 max-md:flex-wrap max-md:gap-14 max-sm:flex-col max-sm:items-start max-sm:gap-2"
          >
            <div class="flex flex-col justify-start">
              <p
                class="text-1.625rem font-medium font-nohemi leading-normal text-white-1200 my-6 max-lg:text-lg max-sm:text-1.625rem"
              >
                Pages
              </p>

              <div class="flex items-center gap-20">
                <div class="flex flex-col gap-2">
                  <router-link
                    to="/"
                    class="text-xl font-light font-inter leading-normal text-white-1200 focus-visible:text-rose-500 hover:text-rose-500 no-underline hover:no-underline max-lg:text-base max-sm:text-xl max-sm:font-normal"
                  >
                    Home
                  </router-link>

                  <a
                    href="https://arcana.network/auth"
                    target="_blank"
                    class="text-xl font-light font-inter leading-normal text-white-1200 focus-visible:text-rose-500 hover:text-rose-500 no-underline hover:no-underline max-lg:text-base max-sm:text-xl max-sm:font-normal"
                    >Auth</a
                  >

                  <a
                    href="https://stakingv1.arcana.network/"
                    target="_blank"
                    class="text-xl font-light font-inter leading-normal text-white-1200 focus-visible:text-rose-500 hover:text-rose-500 no-underline hover:no-underline max-lg:text-base max-sm:text-xl max-sm:font-normal"
                    >Staking V1</a
                  >

                  <a
                    href="https://blog.arcana.network/"
                    target="_blank"
                    class="text-xl font-light font-inter leading-normal text-white-1200 focus-visible:text-rose-500 hover:text-rose-500 no-underline hover:no-underline max-lg:text-base max-sm:text-xl max-sm:font-normal"
                    >Blog</a
                  >

                  <a
                    href="https://arcananetwork.notion.site/Arcana-Technical-Docs-a1d7fd0d2970452586c693e4fee14d08"
                    target="_blank"
                    class="text-xl font-light font-inter leading-normal text-white-1200 focus-visible:text-rose-500 hover:text-rose-500 no-underline hover:no-underline max-lg:text-base max-sm:text-xl max-sm:font-normal"
                    >Technical Paper
                  </a>
                </div>
              </div>
            </div>
            <div class="flex flex-col justify-start">
              <div class="flex items-start gap-20">
                <div class="flex flex-col gap-2">
                  <a
                    href="https://arcana.network/chain-abstraction"
                    target="_blank"
                    class="text-xl font-light font-inter leading-normal text-white-1200 focus-visible:text-rose-500 hover:text-rose-500 no-underline hover:no-underline max-lg:text-base max-sm:text-xl max-sm:font-normal"
                    >Chain Abstraction</a
                  >

                  <a
                    href="https://sendit.arcana.network/"
                    target="_blank"
                    class="text-xl font-light font-inter leading-normal text-white-1200 focus-visible:text-rose-500 hover:text-rose-500 no-underline hover:no-underline max-lg:text-base max-sm:text-xl max-sm:font-normal"
                    >SendIt</a
                  >

                  <a
                    href="https://arcana.network/ecosystem"
                    target="_blank"
                    class="text-xl font-light font-inter leading-normal text-white-1200 focus-visible:text-rose-500 hover:text-rose-500 no-underline hover:no-underline max-lg:text-base max-sm:text-xl max-sm:font-normal"
                    >Ecosystem</a
                  >

                  <a
                    href="https://arcana.network/airdrop"
                    target="_blank"
                    class="text-xl font-light font-inter leading-normal text-white-1200 focus-visible:text-rose-500 hover:text-rose-500 no-underline hover:no-underline max-lg:text-base max-sm:text-xl max-sm:font-normal"
                    >Airdrop</a
                  >
                  <a
                    href="https://arcana.network/privacy"
                    target="_blank"
                    class="text-xl font-light font-inter leading-normal text-white-1200 focus-visible:text-rose-500 hover:text-rose-500 no-underline hover:no-underline max-lg:text-base max-sm:text-xl max-sm:font-normal"
                    >Privacy Policy</a
                  >
                </div>
              </div>
            </div>
            <div class="flex flex-col justify-start">
              <div class="flex items-center gap-20">
                <div class="flex flex-col gap-2">
                  <a
                    href="https://arcana.network/wallet"
                    target="_blank"
                    class="text-xl font-light font-inter leading-normal text-white-1200 focus-visible:text-rose-500 hover:text-rose-500 no-underline hover:no-underline max-lg:text-base max-sm:text-xl max-sm:font-normal"
                    >Wallet</a
                  >

                  <a
                    href="https://arcana.network/token"
                    target="_blank"
                    class="text-xl font-light font-inter leading-normal text-white-1200 focus-visible:text-rose-500 hover:text-rose-500 no-underline hover:no-underline max-lg:text-base max-sm:text-xl max-sm:font-normal"
                    >Token</a
                  >

                  <a
                    href="https://arcana.network/about"
                    target="_blank"
                    class="text-xl font-light font-inter leading-normal text-white-1200 focus-visible:text-rose-500 hover:text-rose-500 no-underline hover:no-underline max-lg:text-base max-sm:text-xl max-sm:font-normal"
                    >About</a
                  >

                  <a
                    href="https://docs.arcana.network/"
                    target="_blank"
                    class="text-xl font-light font-inter leading-normal text-white-1200 focus-visible:text-rose-500 hover:text-rose-500 no-underline hover:no-underline max-lg:text-base max-sm:text-xl max-sm:font-normal"
                    >Docs</a
                  >
                  <a
                    href="https://arcana.network/wallet-extension-terms"
                    target="_blank"
                    class="text-xl font-light font-inter leading-normal text-white-1200 focus-visible:text-rose-500 hover:text-rose-500 no-underline hover:no-underline max-lg:text-base max-sm:text-xl max-sm:font-normal"
                    >Terms & Conditions</a
                  >
                </div>
              </div>
            </div>

            <div class="flex justify-end flex-1 z-50 max-xl:hidden">
              <div class="bg-white-1000 p-6 rounded-lg text-start">
                <h4 class="text-2xl font-bold font-nohemi text-white-1200">
                  Schedule a Demo
                </h4>
                <p
                  class="text-lg text-white-1100 font-inter font-normal text-start leading-8 mt-3"
                >
                  The call is completely free and no <br />
                  commitment is required.
                </p>

                <button
                  class="w-full bg-white-100 border border-solid border-blueGray-800 text-blueGray-800 transition-all enabled:hover:bg-rose-500 enabled:focus-visible:bg-rose-500 rounded-full h-12 disabled:opacity-30 font-inter text-sm font-semibold leading-normal mt-6 px-4 py-2"
                  @click.stop="openCalendly"
                >
                  Pick A Time Slot
                </button>
              </div>
            </div>
          </div>
          <div
            class="flex items-start gap-28 max-md:flex-wrap max-md:gap-14 max-sm:flex-col max-sm:items-start max-sm:gap-2"
          >
            <div class="flex flex-col justify-start">
              <h3
                class="text-1.625rem font-medium font-nohemi leading-normal text-white-1200 my-6 max-lg:text-lg max-sm:text-1.625rem"
              >
                Support
              </h3>

              <div class="flex items-center gap-20">
                <div class="flex flex-col gap-2">
                  <a
                    href="https://status.arcana.network/"
                    target="_blank"
                    class="text-xl font-light font-inter leading-normal text-white-1200 focus-visible:text-rose-500 hover:text-rose-500 no-underline hover:no-underline max-lg:text-base max-sm:text-xl max-sm:font-normal"
                    >Service Status</a
                  >
                  <a
                    href="mailto:support@arcana.network"
                    class="text-xl font-light font-inter leading-normal text-white-1200 focus-visible:text-rose-500 hover:text-rose-500 no-underline hover:no-underline max-lg:text-base max-sm:text-xl max-sm:font-normal"
                    >Email</a
                  >

                  <a
                    href="https://discord.com/invite/6g7fQvEpdy"
                    target="_blank"
                    class="text-xl font-light font-inter leading-normal text-white-1200 focus-visible:text-rose-500 hover:text-rose-500 no-underline hover:no-underline max-lg:text-base max-sm:text-xl max-sm:font-normal"
                    >Discord</a
                  >

                  <a
                    href="https://github.com/arcana-network"
                    target="_blank"
                    class="text-xl font-light font-inter leading-normal text-white-1200 focus-visible:text-rose-500 hover:text-rose-500 no-underline hover:no-underline max-lg:text-base max-sm:text-xl max-sm:font-normal"
                    >GitHub</a
                  >
                </div>
              </div>
            </div>
            <div class="flex flex-col justify-start">
              <h3
                class="text-1.625rem font-medium font-nohemi leading-normal text-white-200 my-6 max-lg:text-lg max-sm:font-normal max-sm:text-1.625rem"
              >
                Press Kit
              </h3>

              <div class="flex items-start gap-20">
                <div class="flex flex-col gap-2">
                  <a
                    href="https://arcana.network/brand"
                    target="_blank"
                    class="text-xl font-light font-inter leading-normal text-white-1200 focus-visible:text-rose-500 hover:text-rose-500 no-underline hover:no-underline max-lg:text-base max-sm:text-xl max-sm:font-normal"
                    >Brand Files</a
                  >
                </div>
              </div>
            </div>
            <div
              class="flex flex-col justify-start ml-12 max-lg:ml-8 max-md:ml-0"
            >
              <h3
                class="text-1.625rem font-medium font-nohemi leading-normal text-white-1200 my-6 max-lg:text-lg max-sm:text-1.625rem"
              >
                Socials
              </h3>

              <div class="flex items-center gap-20">
                <div class="flex flex-col gap-4">
                  <div class="flex space-x-4 mt-2 items-center">
                    <a href="https://t.me/arcananetwork" target="_blank">
                      <img
                        src="@/assets/images/dashboard/telegram.svg?url"
                        alt="Telegram"
                        class="w-5"
                      />
                    </a>
                    <a href="https://x.com/ArcanaNetwork" target="_blank">
                      <img
                        src="@/assets/images/dashboard/twitter.svg?url"
                        alt="Twitter"
                        class="w-5"
                    /></a>
                    <a
                      href="https://www.linkedin.com/company/arcana-network/mycompany/"
                      target="_blank"
                    >
                      <img
                        src="@/assets/images/dashboard/linkedIn.svg?url"
                        alt="LinkedIn"
                        class="w-5"
                    /></a>
                    <a
                      href="https://www.youtube.com/@ArcanaNetwork"
                      target="_blank"
                    >
                      <img
                        src="@/assets/images/dashboard/youTube.svg?url"
                        alt="YouTube"
                        class="w-5"
                    /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex justify-start flex-1 z-50 xl:hidden">
          <div class="bg-white-1000 p-6 rounded-lg text-start">
            <h4
              class="text-2xl font-bold font-nohemi text-white-1200 max-lg:text-xl max-sm:text-lg"
            >
              Schedule a Demo
            </h4>
            <p
              class="text-lg text-white-1100 font-inter font-normal text-start leading-8 mt-3 max-lg:text-base max-sm:text-0.813rem"
            >
              The call is completely free and no <br />
              commitment is required.
            </p>

            <button
              class="w-full bg-white-1200 border border-solid border-blueGray-800 text-blueGray-800 transition-all enabled:hover:bg-rose-500 enabled:focus-visible:bg-rose-500 rounded-full h-12 disabled:opacity-30 font-inter text-sm font-semibold leading-normal mt-6 px-4 py-2 max-sm:text-xs max-sm:font-medium"
              @click.stop="openCalendly"
            >
              Pick A Time Slot
            </button>
          </div>
        </div>

        <div
          class="font-onest text-blueGray-1000 text-lg font-normal leading-7 mt-4 z-50 max-sm:text-sm max-sm:font-inter"
        >
          Copyright © Arcana Technologies Ltd. All rights reserved.
        </div>
      </div>

      <div class="absolute bottom-0 right-0 z-0">
        <img
          src="@/assets/images/dashboard/footerBackground.png"
          alt="Footer Background"
          class="w-50rem"
        />
      </div>
    </footer>
  </div>
</template>
