import { CA } from '@arcana/ca-sdk'
import { ref, watchEffect } from 'vue'

import { useWalletProvider } from '@/use/useWalletProvider'
import { EthereumProvider } from '@/types/providerTypes'
import { useUserStore } from '@/stores/user'
import { useAllowanceStore } from '@/stores/allowance'
import { useAppKitAccount } from '@reown/appkit/vue'

let caSDK: CA | null = null
let previousProvider: EthereumProvider | null = null
let isProcessingAccountChange = false

const useCaSdkAuth = async () => {
  const providerIns = ref<EthereumProvider>(await useWalletProvider())
  const user = useUserStore()
  const allowanceStore = useAllowanceStore()
  const userAccount = useAppKitAccount()

  const initializeCA = async (provider: EthereumProvider) => {
    try {
      if (!caSDK) {
        console.log('Initializing CA SDK...')

        caSDK = new CA()
        caSDK.setEVMProvider(provider)

        await caSDK.init()
        console.log('CA SDK initialized:', caSDK)
        const balances = await caSDK.getUnifiedBalances()
        console.log(balances)

        const displayableAssets =
          balances &&
          balances.sort((a, b) => {
            if (a.abstracted && !b.abstracted) {
              return -1
            }
            if (!a.abstracted && b.abstracted) {
              return 1
            }
            return 0
          })
        user.setAsset(displayableAssets)
        if (userAccount?.value?.address) {
          user.fetchXPBalance(userAccount?.value?.address as `0x${string}`)
        }
        const allAllowance = await caSDK.allowance().get()
        allowanceStore.setUserAllowance(allAllowance)
      }

      return caSDK
    } catch (error) {
      console.error('Error initializing CA SDK:', error)
      return null
    }
  }

  // Watch the provider for changes and initialize the SDK
  watchEffect(async () => {
    if (providerIns.value) {
      if (previousProvider !== providerIns.value) {
        console.log('Provider has changed. Resetting CA SDK...')
        caSDK = null
        previousProvider = providerIns.value
        await initializeCA(providerIns.value)
      }
    }
  })

  const handleAccountsChanged = async (accounts: string[]) => {
    console.log('Accounts changed:', accounts)

    if (isProcessingAccountChange) {
      console.log('Already processing account change, skipping...')
      return
    }

    isProcessingAccountChange = true
    setTimeout(async () => {
      try {
        console.log(userAccount.value.isConnected)

        if (caSDK && userAccount.value.isConnected === true) {
          console.log('CA SDK re-initializing on account change...')
          await caSDK.init()

          // Fetch and update balances
          const balances = await caSDK.getUnifiedBalances()
          const displayableAssets = balances?.sort((a, b) => {
            if (a.abstracted && !b.abstracted) return -1
            if (!a.abstracted && b.abstracted) return 1
            return 0
          })

          console.log('Updated assets:', displayableAssets)
          user.setAsset(displayableAssets)
          if (userAccount?.value?.address) {
            user.fetchXPBalance(userAccount?.value?.address as `0x${string}`)
          }
          const allAllowance = await caSDK.allowance().get()
          allowanceStore.setUserAllowance(allAllowance)
        } else {
          console.log(
            'Skipped CA SDK reinitialization (route or caSDK missing).',
          )
        }
      } catch (error) {
        console.error('Error handling account change:', error)
      } finally {
        isProcessingAccountChange = false
      }
    }, 2000) // Delay to ensure account updates settle
  }

  providerIns.value.on('accountsChanged', handleAccountsChanged)

  // Return the current SDK instance (if available)
  return caSDK
}

export { useCaSdkAuth }
