<script lang="ts" setup>
import type { CA, ProgressStep } from '@arcana/ca-sdk'
import { Avatar, Field, NumberInput, Select } from '@ark-ui/vue'
import { useAppKitAccount } from '@reown/appkit/vue'
import dayjs from 'dayjs'
import Decimal from 'decimal.js'
import { ZeroAddress } from 'ethers'
import { computed, nextTick, onMounted, onUnmounted, ref, watch } from 'vue'

import ArrowIcon from '@/assets/images/svg/ArrowUp.svg'
import CheckIcon from '@/assets/images/svg/Check.svg'
import ChevronDownIcon from '@/assets/images/svg/ChevronDown.svg'
import InfoIcon from '@/assets/images/svg/InfoCircle.svg'
import DiamondIcon from '@/assets/images/svg/Daimond.svg'
import AppTransaction from '@/components/layout/AppTransaction.vue'
import AppTooltip from '@/components/shared/AppTooltip.vue'
import { useTokenStore } from '@/stores/token'
import { useUserStore } from '@/stores/user'
import { AllowanceDataType } from '@/types/allowanceTypes'
import { Asset, Chain as ChainDetails } from '@/types/balanceTypes'
import { Chain } from '@/types/chainTypes'
import { IntentDataType } from '@/types/intentTypes'
import { useCaSdkAuth } from '@/use/CaSdkAuth'
import AppBalance from '@/pages/AppBalance.vue'
import { MAINNET_CHAINS } from '@/utils/constants'
import { devLogger } from '@/utils/devLogger'
import { clearAsyncInterval, setAsyncInterval } from '@/utils/asyncInterval'
import { useErrorToast } from '@/use/useErrorToast'
import { SwitchChainError } from 'viem'
import { switchChain } from '@/utils/switchChain'
import { trackEvent } from '@/segment/segment'
import { requestXPForAddress } from '@/services/arcanaNetwork'

let caSdkAuth: CA | null = null
const user = useUserStore()
const useAccount = useAppKitAccount()
const tokenStore = useTokenStore()
const userToast = useErrorToast()
const timer = ref<string>('0.00')
const timerInterval = ref<any>()
const isModalOpen = ref<boolean>(false)
const openIntentLoader = ref<boolean>(false)
const allowanceLoader = ref<boolean>(false)
const txHash = ref<string>('')
const txError = ref<boolean>(false)
const txErrorMsg = ref<string>('')
const showXPTransaction = ref<boolean>(false)
const CATransaction = ref<boolean>(false)
const selectedOptions = ref<{
  token: string[]
  chain: string[]
  amount: string | null
}>({
  token: [''],
  chain: [''],
  amount: null,
})
const submitSteps = ref<{
  inProgress: boolean
  completed: boolean
  steps: { type: string; typeID: string; done: boolean; data: any }[]
}>({
  inProgress: false,
  steps: [],
  completed: false,
})
const feeData = ref<{
  maxFeePerGas: string
  gasPrice: string
  maxPriorityFeePerGas: string
}>({
  maxFeePerGas: '0',
  gasPrice: '0',
  maxPriorityFeePerGas: '0',
})
const allowanceData = ref<AllowanceDataType>({
  open: false,
  data: [],
  allow: null,
  deny: null,
})
const intentData = ref<IntentDataType>({
  open: false,
  allow: () => ({}),
  deny: () => ({}),
  refresh: null,
  intent: null,
  intervalHandler: null,
  intentRefreshing: false,
})
const allLoader = ref<{
  startTransaction: boolean
  stepsLoader: boolean
}>({
  startTransaction: false,
  stepsLoader: false,
})

const resetSubmitSteps = () => {
  submitSteps.value.inProgress = false
  submitSteps.value.steps = []
  submitSteps.value.completed = false
}

const getTokenAndChainDetails = (assets: Asset[]) => {
  const tokenSet = new Set<string>()
  const chainMap = new Map<number, ChainDetails>()

  assets.forEach((asset) => {
    tokenSet.add(asset.symbol)

    asset.breakdown.forEach((breakdown) => {
      const chainWithSymbol = {
        ...breakdown.chain,
        abstracted: asset.abstracted,
        network: breakdown.network,
      }

      if (!chainMap.has(breakdown.chain.id)) {
        chainMap.set(Number(breakdown.chain.id), chainWithSymbol)
      }
    })
  })

  return {
    token: Array.from(tokenSet),
    chain: Array.from(chainMap.values()),
  }
}

const chainList = computed(() => getTokenAndChainDetails(user.assets).chain)

const selectedChain = computed(() => {
  return chainList.value.find(
    (c) => c.id.toString() === selectedOptions.value.chain[0],
  )
})

const selectedToken = computed(() => {
  return availableTokens.value.find((token) => {
    return token.breakdown.find(
      (b) => b.contractAddress.toString() === selectedOptions.value.token[0],
    )
  })
})

const availableTokens = computed(() => {
  const userAssets = user.assets

  return userAssets
    .filter((asset) =>
      asset.breakdown.find(
        (b) =>
          b.chain.id.toString() === selectedOptions.value.chain[0].toString(),
      ),
    )
    .map((asset) => {
      return {
        ...asset,
        value: asset.breakdown.find(
          (b) =>
            b.chain.id.toString() === selectedOptions.value.chain[0].toString(),
        )?.contractAddress,
      }
    })
})

const getChainById = (chainId: number) => {
  return (
    MAINNET_CHAINS.find((chain: Chain) => chain.id === chainId) || ({} as Chain)
  )
}

const reduceFeeData = () => {
  if (selectedOptions.value.token[0] !== ZeroAddress) return new Decimal(0)
  const f = new Decimal(
    feeData.value.maxFeePerGas || feeData.value.gasPrice || '0',
  )
    .div(
      Decimal.pow(
        10,
        getChainById(Number(selectedOptions.value.chain[0])).nativeCurrency
          .decimals || 0,
      ),
    )
    .mul(21000)
  return f.add(Decimal.mul(f, 0.05))
}

const getSymbolByContractAddress = (
  assets: Asset[],
  contractAddress: string,
): string => {
  const matchedItem = assets?.filter((item) =>
    item.breakdown.find(
      (item) =>
        item.contractAddress.toLowerCase() === contractAddress.toLowerCase(),
    ),
  )

  return matchedItem[0].symbol
}

const startTimer = () => {
  const submissionTime = Date.now()

  timerInterval.value = setInterval(() => {
    timer.value = dayjs().diff(submissionTime, 'second', true).toFixed(2)
  }, 20)
}

const intentDataOpen = () => {
  openIntentLoader.value = true
}

const intentDataClose = () => {
  openIntentLoader.value = false
}

const startSubmitLoader = () => {
  allLoader.value.stepsLoader = true
}

const clearTime = () => {
  clearInterval(timerInterval.value)
}

const clearTransferData = () => {
  selectedOptions.value.token = ['']
  selectedOptions.value.chain = ['']
  selectedOptions.value.amount = null
}

const allowanceLoaderOpen = () => {
  allowanceLoader.value = true
}

const allowanceLoaderClose = () => {
  allowanceLoader.value = false
}

const handleBridge = async () => {
  isModalOpen.value = true
  allLoader.value.startTransaction = true
  allLoader.value.stepsLoader = false
  txError.value = false
  txErrorMsg.value = ''
  txHash.value = ''
  resetSubmitSteps()
  try {
    const token = getSymbolByContractAddress(
      availableTokens.value,
      selectedOptions.value.token[0],
    )

    if (caSdkAuth) {
      const result = await caSdkAuth
        .bridge()
        .amount(Number(selectedOptions.value.amount))
        .chain(Number(selectedOptions.value.chain[0]))
        .token(token)
        .exec()
      if (result) {
        txHash.value = result as string
      }
      allLoader.value.startTransaction = false
      submitSteps.value.completed = true
    }
  } catch (error: any) {
    resetSubmitSteps()
    devLogger.log('Transfer Failed:', error)
    trackEvent('Failed Bridge', {
      appName: 'Arcana Pay App',
      walletAddress: useAccount.value.address,
      environment: import.meta.env.VITE_ENVIOURMENT,
      buttonName: 'Failed Bridge',
      timestamp: new Date().toISOString(),
    })
    txHash.value = ''
    allLoader.value.startTransaction = false
    txError.value = true
    allowanceLoaderClose()
    clearInterval(timerInterval.value)
    userToast.createErrorToast(error)
    if (error?.message) {
      txErrorMsg.value = error?.message
    }
    if (
      error instanceof SwitchChainError &&
      error.message.includes('Unrecognized chain ID')
    ) {
      console.error('Chain not recognized. Try adding the chain first.')
      await switchChain(selectedOptions.value.chain[0] as string)
    }
  } finally {
    allLoader.value.startTransaction = false
    resetIntentData()
    clearTransferData()
    clearInterval(timerInterval.value)
    if (useAccount?.value?.address) {
      user.fetchXPBalance(useAccount?.value?.address as `0x${string}`)
    }
    trackEvent('Completed Bridge', {
      appName: 'Arcana Pay App',
      walletAddress: useAccount.value.address,
      environment: import.meta.env.VITE_ENVIOURMENT,
      buttonName: 'Completed Bridge',
      intentURL: intentURL.value ? intentURL.value : '',
      timestamp: new Date().toISOString(),
    })
    showXPTransaction.value = true
    if (caSdkAuth) {
      const balances = await caSdkAuth.getUnifiedBalances()
      console.log(balances)
      const displayableAssets =
        balances &&
        balances.sort((a, b) => {
          if (a.abstracted && !b.abstracted) {
            return -1
          }
          if (!a.abstracted && b.abstracted) {
            return 1
          }
          return 0
        })
      user.setAsset(displayableAssets)
    }
  }
}

const intentURL = computed(() => {
  const stepWithIntentURL = submitSteps.value.steps.find(
    (step) => step.data?.explorerURL,
  )
  return stepWithIntentURL?.data?.explorerURL || ''
})

const intentId = computed(() => {
  const stepWithIntentURL = submitSteps.value.steps.find(
    (step) => step?.data?.intentHash,
  )
  return stepWithIntentURL?.data?.intentHash || ''
})

const caSDKEventListener = (data: any) => {
  switch (data.type) {
    case 'EXPECTED_STEPS': {
      console.log('Expected steps', data.data)
      submitSteps.value.steps = data.data.map((s: ProgressStep) => ({
        ...s,
        done: false,
      }))
      submitSteps.value.inProgress = true
      break
    }
    case 'STEP_DONE': {
      console.log('Step done', data.data)
      const v = submitSteps.value.steps.find((s) => {
        return s.typeID === data.data.typeID
      })
      console.log({ v })
      if (v) {
        v.done = true
        if (data.data.data) {
          v.data = data.data.data
        }
      }
      break
    }
  }
}

const handleMax = async () => {
  if (selectedToken.value) {
    selectedOptions.value.amount = new Decimal(
      selectedToken.value?.balance || 0,
    )
      .minus(reduceFeeData())
      .toString()

    await nextTick()
  }
}

const handleAmountInput = (event: Event) => {
  const input = (event.target as HTMLInputElement).value

  if (!input || isNaN(Number(input))) {
    selectedOptions.value.amount = ''
    return
  }

  const amount = new Decimal(input)

  if (selectedToken.value) {
    const maxAllowed = new Decimal(selectedToken.value.balance || 0).minus(
      reduceFeeData(),
    )
    if (amount.greaterThan(maxAllowed)) {
      selectedOptions.value.amount = maxAllowed.lessThan(0)
        ? '0'
        : maxAllowed.toString()
    } else {
      selectedOptions.value.amount = input
    }
  }
}

const handleModalDismiss = () => {
  isModalOpen.value = false
  showXPTransaction.value = false
  CATransaction.value = false
  if (useAccount?.value?.address) {
    user.fetchXPBalance(useAccount?.value?.address as `0x${string}`)
  }
}

const clearIntervalHandler = () => {
  if (intentData.value.intervalHandler != null) {
    clearAsyncInterval(intentData.value.intervalHandler)
    intentData.value.intervalHandler = null
  }
}

const resetIntentData = () => {
  if (intentData.value.intervalHandler != null) {
    clearAsyncInterval(intentData.value.intervalHandler)
    intentData.value.intervalHandler = null
  }
  intentData.value.open = false
  intentData.value.allow = () => ({})
  intentData.value.deny = () => ({})
  intentData.value.refresh = null
  intentData.value.intent = null
}

const resetAllowanceData = () => {
  allowanceData.value.open = false
  allowanceData.value.allow = () => ({})
  allowanceData.value.deny = () => ({})
  allowanceData.value.data = []
}

const setupAllowanceHook = (caSdkAuth: CA) => {
  caSdkAuth.setOnAllowanceHook(async ({ allow, deny, sources }: any) => {
    allowanceData.value.open = true
    allowanceData.value.allow = allow
    allowanceData.value.deny = deny
    allowanceData.value.data = sources
  })
}

const setupIntentHook = (caSdkAuth: CA) => {
  caSdkAuth.setOnIntentHook(({ intent, allow, deny, refresh }: any) => {
    console.log({ intent })
    CATransaction.value = true
    resetAllowanceData()
    intentData.value.open = true
    intentData.value.allow = allow
    intentData.value.deny = deny
    intentData.value.refresh = refresh
    intentData.value.intent = intent
    setTimeout(() => {
      intentData.value.intervalHandler = setAsyncInterval(async () => {
        if (intentData.value.refresh) {
          console.log('intentRefreshStarted')
          intentData.value.intentRefreshing = true
          intentData.value.intent = await intentData.value.refresh!()
          intentData.value.intentRefreshing = false
          console.log('intentRefreshEnded')
        }
      }, 5000)
    }, 5000)
  })
}

const checkTxCount = (universeData: string) => {
  const universeMap: Record<string, string> = {
    evm: 'ETHEREUM',
    fuel: 'FUEL',
  }
  const universe = universeMap[universeData.toLowerCase()] || universeData
  return user.XPBalance.some(
    (item) => item.universe === universe && item.XPResult?.tx_count !== 10,
  )
}

const isTxCountValid = computed(() => {
  if (selectedChain.value?.network) {
    return checkTxCount(selectedChain.value?.network)
  }
})

const totalStats = computed(() => {
  return user.XPBalance.reduce(
    (acc, item) => {
      acc.total_xp += item?.XPResult?.total_xp
      acc.tx_count += item?.XPResult?.tx_count
      return acc
    },
    { total_xp: 0, tx_count: 0 },
  )
})

const shareOnTwitter = async () => {
  try {
    if (intentId?.value) {
      const res = await requestXPForAddress(intentId.value)
      console.log(res, 'res')
      if (res.ok) {
        const text = encodeURIComponent(
          `Just used @ArcanaNetwork's Chain Abstraction Protocol to spend my unified balance across chains without manual bridging. Web3 Made Effortless!`,
        )
        window.open(`https://twitter.com/intent/tweet?text=${text}`, '_blank')
      }
    }
  } catch (error) {
    console.log('Not Found RFFId', error)
  }
}

watch(
  () => tokenStore.selectedToken,
  (newToken: Asset | null) => {
    if (newToken) {
      selectedOptions.value.chain = [newToken.breakdown[0].chain.id.toString()]
      selectedOptions.value.token = [
        newToken.breakdown[0].contractAddress.toString(),
      ]
    }
  },
  { immediate: true },
)

onMounted(async () => {
  try {
    caSdkAuth = await useCaSdkAuth()
    if (caSdkAuth) {
      setupAllowanceHook(caSdkAuth)
      setupIntentHook(caSdkAuth)
      caSdkAuth.addCAEventListener(caSDKEventListener)
    }
  } catch (error) {
    console.error('Error initializing CA SDK Auth:', error)
  }
})

onUnmounted(() => {
  if (caSdkAuth) {
    caSdkAuth.removeCAEventListener(caSDKEventListener)
  }
})
</script>

<template>
  <div class="flex-1 p-12 overflow-auto max-sm:p-6">
    <div class="">
      <div
        class="max-sm:relative max-sm:-m-6 max-sm:bg-background-500 max-sm:mb-4"
      >
        <AppBalance title="Bridge" />
      </div>
      <div
        class="bg-background-500 w-1/2 rounded-lg shadow-md p-6 space-y-4 text-blueGray-800 font-inter font-normal text-sm max-md:w-full"
      >
        <Field.Root>
          <Select.Root
            v-model="selectedOptions.chain"
            class="w-full flex flex-col gap-1 relative z-40 isolate"
            :items="chainList"
          >
            <Select.Label
              class="font-inter text-sm font-normal text-blueGray-800 placeholder:text-blueGray-600"
              >Destination Chain</Select.Label
            >
            <Select.Control class="outline-none field">
              <Select.Trigger
                class="flex rounded-md items-center w-full font-inter text-base font-medium text-blueGray-800 shadow-sm bg-white-100 text-start h-10 px-4 py-2 border border-background-400 placeholder:text-blueGray-600"
              >
                <div
                  class="flex-grow flex items-center gap-2 font-medium text-base"
                >
                  <Avatar.Root>
                    <Avatar.Image
                      :src="selectedChain?.logo"
                      class="w-5 h-5 rounded-full"
                    />
                  </Avatar.Root>
                  <span>{{ selectedChain?.name || 'Chain' }}</span>
                </div>
                <Select.Indicator>
                  <ChevronDownIcon class="w-4 h-4 stroke-blueGray-800" />
                </Select.Indicator>
              </Select.Trigger>
            </Select.Control>
            <Select.Positioner class="w-full z-50">
              <Select.Content
                class="max-h-90 w-full rounded-lg text-sm bg-white-100"
              >
                <Select.ItemGroup>
                  <Select.Item
                    v-for="chain in chainList"
                    :key="chain.id"
                    :item="chain.id.toString()"
                    class="px-4 py-3 w-full flex rounded-md justify-between hover:bg-blueGray-300"
                  >
                    <div class="flex items-center gap-2">
                      <Avatar.Root>
                        <Avatar.Fallback class="w-5 h-5 rounded-full">{{
                          chain.name.split(' ')[0].substring(0, 2).toUpperCase()
                        }}</Avatar.Fallback>
                        <Avatar.Image
                          :src="chain.logo"
                          class="w-5 h-5 rounded-full"
                        />
                      </Avatar.Root>
                      <span>{{ chain.name }}</span>
                      <div
                        v-if="chain?.network && checkTxCount(chain?.network)"
                        class="text-blue-500 text-xs font-normal flex items-center gap-1 px-1 py-0 rounded-md bg-blue-100"
                      >
                        Earn 10XP
                      </div>
                      <Select.ItemText class="hidden">{{
                        chain.id
                      }}</Select.ItemText>
                    </div>
                    <Select.ItemIndicator
                      ><CheckIcon class="w-5 h-5 stroke-black-700"
                    /></Select.ItemIndicator>
                  </Select.Item>
                </Select.ItemGroup>
              </Select.Content>
            </Select.Positioner>
            <Select.HiddenSelect />
          </Select.Root>
        </Field.Root>

        <Field.Root>
          <Select.Root
            v-model="selectedOptions.token"
            class="w-full flex flex-col gap-1 relative z-10 isolate"
            :items="availableTokens"
          >
            <Select.Label
              class="font-inter text-sm font-normal text-blueGray-800 placeholder:text-blueGray-600"
              >Token</Select.Label
            >
            <Select.Control class="outline-none field">
              <Select.Trigger
                class="flex rounded-md items-center w-full font-inter text-base font-medium text-blueGray-800 shadow-sm bg-white-100 text-start h-10 px-4 py-2 border border-background-400 placeholder:text-blueGray-600"
              >
                <div
                  class="flex-grow flex items-center gap-2 font-medium text-base"
                >
                  <Avatar.Root>
                    <Avatar.Image
                      :src="selectedToken?.icon"
                      class="w-5 h-5 rounded-full"
                    />
                  </Avatar.Root>
                  <span>{{ selectedToken?.symbol || 'Token' }}</span>
                  <div
                    v-if="selectedToken?.abstracted"
                    class="text-rose-500 text-0.625rem font-inter font-normal flex items-center gap-1 p-1 rounded-full bg-rose-200"
                  >
                    Chain Abstracted
                    <AppTooltip message="Chain Abstracted">
                      <InfoIcon
                        class="h-3 w-3 stroke-rose-500 stroke-cap-round"
                      />
                    </AppTooltip>
                  </div>
                </div>
                <Select.Indicator>
                  <ChevronDownIcon class="w-4 h-4 stroke-blueGray-800" />
                </Select.Indicator>
              </Select.Trigger>
            </Select.Control>
            <Select.Positioner class="w-full z-50">
              <Select.Content
                class="max-h-60 w-full rounded-lg text-sm bg-white-100"
              >
                <Select.ItemGroup>
                  <Select.Item
                    v-for="token in availableTokens"
                    :key="token.breakdown[0].contractAddress"
                    :item="token.breakdown[0].contractAddress"
                    class="px-4 py-3 w-full flex rounded-md justify-between hover:bg-blueGray-300"
                  >
                    <div class="flex items-center gap-2">
                      <Avatar.Root>
                        <Avatar.Fallback class="w-5 h-5 rounded-full">{{
                          token.symbol
                            .split(' ')[0]
                            .substring(0, 2)
                            .toUpperCase()
                        }}</Avatar.Fallback>
                        <Avatar.Image
                          :src="token.icon"
                          class="w-5 h-5 rounded-full"
                        />
                      </Avatar.Root>
                      <span>{{ token.symbol }}</span>
                      <div
                        v-if="token?.abstracted"
                        class="text-rose-500 text-0.625rem font-inter font-normal flex items-center gap-1 p-1 rounded-full bg-rose-200"
                      >
                        Chain Abstracted
                        <AppTooltip message="Chain Abstracted">
                          <InfoIcon
                            class="h-3 w-3 stroke-rose-500 stroke-cap-round"
                          />
                        </AppTooltip>
                      </div>
                    </div>
                    <Select.ItemIndicator
                      ><CheckIcon class="w-5 h-5 stroke-black-700"
                    /></Select.ItemIndicator>
                  </Select.Item>
                </Select.ItemGroup>
              </Select.Content>
            </Select.Positioner>
            <Select.HiddenSelect />
          </Select.Root>
        </Field.Root>

        <Field.Root>
          <NumberInput.Root>
            <NumberInput.Label
              class="font-inter text-sm font-normal text-blueGray-800"
              >Amount</NumberInput.Label
            >

            <div class="relative flex items-center">
              <NumberInput.Input
                :value="selectedOptions.amount"
                class="w-full shadow-sm border border-background-400 placeholder:text-blueGray-600"
                placeholder="0"
                :min="0"
                @input="handleAmountInput"
                @focus="
                  () => {
                    if (
                      selectedOptions.amount &&
                      new Decimal(selectedOptions.amount).greaterThan(
                        new Decimal(selectedToken?.balance || 0)
                          .minus(reduceFeeData())
                          .toNumber(),
                      )
                    ) {
                      selectedOptions.amount = new Decimal(
                        selectedToken?.balance || 0,
                      )
                        .minus(reduceFeeData())
                        .toString()
                      if (new Decimal(selectedOptions.amount).lessThan(0)) {
                        selectedOptions.amount = '0'
                      }
                    }
                  }
                "
              />

              <span
                class="absolute flex items-center gap-2 right-2 px-4 py-1 text-sm font-semibold font-inter uppercase border-l-2 border-blueGray-200 cursor-pointer text-blueGray-700"
                :disabled="!selectedToken"
                @click.stop="handleMax"
              >
                <ArrowIcon class="stroke-blueGray-700" />
                Max
              </span>
            </div>
          </NumberInput.Root>
        </Field.Root>

        <div class="flex items-center justify-start">
          Available:
          {{ new Decimal(selectedToken?.balance || '0').toDecimalPlaces(6) }}
          {{ selectedToken?.symbol }}
        </div>
      </div>

      <div
        v-if="isTxCountValid"
        class="w-1/2 bg-rose-500 mt-4 p-3 rounded-lg flex items-start gap-2 max-md:w-full"
      >
        <DiamondIcon class="h-4 w-5 stroke-white-100" />
        <div class="font-inter font-medium text-[10px] text-white-100">
          <span class="font-bold">Earn 10XP </span>
          when you transact on the {{ selectedChain?.name }} chain.
        </div>
      </div>

      <AppTransaction
        :allowance-details="allowanceData"
        :intent-details="intentData"
        :form-address="useAccount.address as string"
        :to-address="useAccount.address as string"
        :submit-steps="submitSteps"
        :is-modal-open="isModalOpen"
        :timer="timer"
        :open-intent-loader="openIntentLoader"
        :disabbled="
          !selectedOptions.amount ||
          !selectedOptions.chain[0] ||
          !selectedOptions.token[0]
        "
        :interection="allLoader.startTransaction"
        :submit-loader="allLoader.stepsLoader"
        :tx-error="txError"
        :tx-hash="txHash"
        :allowanceLoader="allowanceLoader"
        :txErrorMsg="txErrorMsg"
        :showXPTransaction="showXPTransaction"
        :totalStats="totalStats"
        :CATransaction="CATransaction"
        type="Receive"
        @start-submit-loader="startSubmitLoader"
        @continue="handleBridge"
        @rest-intent-data="resetIntentData"
        @start-timer="startTimer"
        @close-modal="handleModalDismiss"
        @intentDataOpen="intentDataOpen"
        @intentDataClose="intentDataClose"
        @clearTime="clearTime"
        @rest-allowance-data="resetAllowanceData"
        @allowance-loader-open="allowanceLoaderOpen"
        @clearIntentHandler="clearIntervalHandler"
        @share-on-twitter="shareOnTwitter"
      />
    </div>
  </div>
</template>
