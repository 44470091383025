import { QueryClient, VueQueryPlugin } from '@tanstack/vue-query'
import { WagmiPlugin } from '@wagmi/vue'
import { createApp } from 'vue'
import Toast from 'vue-toastification'

import App from '@/App.vue'
import { wagmiAdapter } from '@/composables/config'
import router from '@/router'
import '@fontsource-variable/inter'
import '@fontsource-variable/onest'
import { store } from '@/stores'
import { TOAST_TIME_OUT } from '@/utils/constants'
import 'vue-toastification/dist/index.css'
import '@/style.css'
import { initSegment } from './segment/segment'

const queryClient = new QueryClient()

const app = createApp(App)

const toastOptions = {
  timeout: TOAST_TIME_OUT,
  closeOnClick: false,
  pauseOnFocusLoss: false,
  pauseOnHover: true,
  draggable: false,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  closeButton: false,
  icon: true,
  rtl: false,
}

initSegment()

app
  .use(WagmiPlugin, { config: wagmiAdapter.wagmiConfig as any })
  .use(VueQueryPlugin, { queryClient })
  .use(router)
  .use(Toast, toastOptions)
  .use(store)
  .mount('#app')
