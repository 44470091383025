import { useAppKitAccount } from '@reown/appkit/vue'
import { createRouter, createWebHistory } from 'vue-router'

import AllowanceSetup from '@/pages/AllowanceSetup.vue'
import Allowance from '@/pages/AppAllowance.vue'
import Bridge from '@/pages/AppBridge.vue'
import Consolidate from '@/pages/AppConsolidate.vue'
import Dashboard from '@/pages/AppDashboard.vue'
import Overview from '@/pages/AppOverview.vue'
import Send from '@/pages/AppSend.vue'
import Wallet from '@/pages/AppWallet.vue'
import { useLoader } from '@/stores/loader'

const routes = [
  {
    path: '/',
    component: Dashboard,
    name: 'Dashboard',
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/apps',
    component: Overview,
    name: 'Overview',
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/apps/transfer',
    component: Send,
    name: 'Send',
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/apps/wallet',
    component: Wallet,
    name: 'Wallet',
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/apps/allowances',
    component: Allowance,
    name: 'Allowances',
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/apps/allowances/setup',
    component: AllowanceSetup,
    name: 'AllowanceSetup',
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/apps/bridge',
    component: Bridge,
    name: 'Bridge',
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/',
  },
  // {
  //   path: '/apps/consolidate',
  //   component: Consolidate,
  //   name: 'Consolidate',
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach(async (to, from, next) => {
  const useAccount = useAppKitAccount()
  const loader = useLoader()

  await new Promise((resolve) => {
    const interval = setInterval(() => {
      if (
        useAccount.value &&
        typeof useAccount.value.isConnected !== 'undefined'
      ) {
        clearInterval(interval)
        resolve(true)
      }
    }, 100)
  })

  loader.setLoader(false)

  if (to.meta.requiresAuth) {
    if (!useAccount.value.isConnected) {
      return next({ path: '/' })
    }
    next()
  } else {
    if (useAccount.value.isConnected && to.path === '/') {
      return next({ path: '/apps' })
    }
    next()
  }
})

export default router
